<template>
<div class='assignOutcomeToClient'>

	<div class='contentPanel' :class='{ previewOpen: compositeOutcome && !manualResult }'>

		<Scroll>

			<div class='step'>

				<template v-if='outcome'>

					<div class='stepHeader'>
						{{$t('views.assignOutcome.step1')}}:
						<span>{{$t('views.assignOutcome.chooseClient.title')}}</span>
					</div>
					<div class='chooseClientWrapper'>
						<div v-if='compositeClient' class='client'>{{compositeClient.firstname}} {{compositeClient.lastname}}<br /><span>{{compositeClient.email}}</span></div>
						<div @click='chooseClient' class='chooseClientButton iconTextButton neutral'>
							<svg class='smallIcon'>
								<use xlink:href='#plus' />
							</svg>
							<span class='label'>{{$t('elements.buttons.client')}}</span>
						</div>
					</div>

				</template>
				<template v-if='client'>

					<div class='stepHeader'>
						{{$t('views.assignOutcome.step1')}}:
						<span>{{$t('views.assignOutcome.chooseOutcome.title')}}</span>
					</div>
					<div class='chooseOutcomeWrapper'>
						<div v-if='compositeOutcome' class='outcome'>{{compositeOutcome.savename}}</div>
						<div @click='chooseOutcome' class='chooseOutcomeButton iconTextButton neutral'>
							<svg class='smallIcon'>
								<use xlink:href='#plus' />
							</svg>
							<span class='label'>{{$t('elements.buttons.outcome')}}</span>
						</div>
					</div>					

				</template>

				<div v-if='canDeliver' class='chooseDelivery'>
					<div class='label' v-html='strChooseDeliver' />
					<div class='options'>
						<div @click='delivery="once"' :class='{ selected: delivery==="once" }'>
							<CheckButton :checked='delivery==="once"' />
							<div>{{$t('views.assignOutcome.chooseDelivery.btnOnce')}}<!--<span>{{$t('views.assignOutcome.chooseDelivery.lblOnce')}}</span>--></div>
						</div>
						<div @click='delivery="intervals"' :class='{ selected: delivery==="intervals" }'>
							<CheckButton :checked='delivery==="intervals"' />
							<div>{{$t('views.assignOutcome.chooseDelivery.btnIntervals')}}<!--<span>{{$t('views.assignOutcome.chooseDelivery.lblIntervals')}}</span>--></div>
						</div>
						<div @click='delivery="manual"' :class='{ selected: delivery==="manual" }'>
							<CheckButton :checked='delivery==="manual"' />
							<div>{{$t('views.assignOutcome.chooseDelivery.btnManual')}}<!--<span>{{$t('views.assignOutcome.chooseDelivery.lblManual')}}</span>--></div>
						</div>						
					</div>
				</div>
			</div>	

			<template v-if='delivery==="once"'>
				<div class='step notes'>
					<div class='stepHeader'>
						{{$t('views.assignOutcome.step2')}} ({{$t('elements.labels.optional')}}):
						<span>{{$t('views.assignOutcome.addNotes.title')}}</span>
					</div>
					<div class='label' v-html='$t("views.assignOutcome.addNotes.p1")' />
					<RichText 
						v-model='notes' 
						:placeholder='$t(`views.assignOutcome.addNotes.ph`)'
						:maxlength='400'
					/>
				</div>
			</template>			

			<template v-else-if='delivery==="intervals"'>
				<div class='step deliveryDates'>
					<div class='stepHeader'>
						{{$t('views.assignOutcome.step2')}}:
						<span>{{$t('views.assignOutcome.chooseDates.title')}}</span>
					</div>
					<div class='dateInputs'>
						<div class='days inputWrapper'>
							<SelectInput v-model='intervalType'>
								<option disabled selected value=''>{{$t('views.assignOutcome.chooseDates.phDates')}}</option>
								<option value='custom'>{{$t('views.assignOutcome.chooseDates.optSpecific')}}</option>
								<option value='every'>{{$t('views.assignOutcome.chooseDates.optDaily')}}</option>
								<option value='alt'>{{$t('views.assignOutcome.chooseDates.optAltDays')}}</option>
								<optgroup :label='$t(`views.assignOutcome.chooseDates.optWeekly`)'>
									<option value='Mo'>{{$t('views.assignOutcome.chooseDates.weekdays.mo')}}</option>
									<option value='Tu'>{{$t('views.assignOutcome.chooseDates.weekdays.tu')}}</option>
									<option value='We'>{{$t('views.assignOutcome.chooseDates.weekdays.we')}}</option>
									<option value='Th'>{{$t('views.assignOutcome.chooseDates.weekdays.th')}}</option>
									<option value='Fr'>{{$t('views.assignOutcome.chooseDates.weekdays.fr')}}</option>
									<option value='Sa'>{{$t('views.assignOutcome.chooseDates.weekdays.sa')}}</option>
									<option value='Su'>{{$t('views.assignOutcome.chooseDates.weekdays.su')}}</option>
								</optgroup>
								<optgroup :label='$t(`views.assignOutcome.chooseDates.optAltWeeks`)'>
									<option value='altMo'>{{$t('views.assignOutcome.chooseDates.weekdays.mo')}}</option>
									<option value='altTu'>{{$t('views.assignOutcome.chooseDates.weekdays.tu')}}</option>
									<option value='altWe'>{{$t('views.assignOutcome.chooseDates.weekdays.we')}}</option>
									<option value='altTh'>{{$t('views.assignOutcome.chooseDates.weekdays.th')}}</option>
									<option value='altFr'>{{$t('views.assignOutcome.chooseDates.weekdays.fr')}}</option>
									<option value='altSa'>{{$t('views.assignOutcome.chooseDates.weekdays.sa')}}</option>
									<option value='altSu'>{{$t('views.assignOutcome.chooseDates.weekdays.su')}}</option>
								</optgroup>
							</SelectInput>
						</div>
						<div class='until' v-if='intervalType'>Until</div>
						<div v-if='intervalType' class='pickEndDate inputWrapper'>
							<PickEndDate 
								v-model='expiresOn' 
								:rows='1'
								:columns='3'
								:placeholder='$t(`views.assignOutcome.chooseDates.phEndDate`)'
								popOverPlace='bottom' 
							/>
						</div>
					</div>
					<template v-if='expiresOn' >
						<div class='label'>{{$t('views.assignOutcome.chooseDates.p1')}}</div>
						<PickReminders 
							v-model='reminders' 
							:intervalType='intervalType'
							:expiresOn='expiresOn'
						/>
					</template>
				</div>
				<div v-if='expiresOn' class='step notes'>
					<div class='stepHeader'>
						{{$t('views.assignOutcome.step3')}} ({{$t('elements.labels.optional')}}):
						<span>{{$t('views.assignOutcome.addNotes.title')}}</span>
					</div>
					<div class='label' v-html='$t("views.assignOutcome.addNotes.p1")' />
					<RichText 
						v-model='notes' 
						:placeholder='$t(`views.assignOutcome.addNotes.ph`)'
						:maxlength='400'
					/>
				</div>				
			</template>	

			<template v-else-if='delivery==="manual" && manualResult'>
				<div class='step manual'>
					<div class='stepHeader'>
						{{$t('views.assignOutcome.step2')}}:
						<span>Verify</span>
					</div>
					<div class='label'>
						<div>
							You are about to manually record the <b>{{compositeOutcome.savename}}</b> questionaire for <b>{{compositeClient.firstname}} {{compositeClient.lastname}}.</b><br /><br />
							<div class='manualDate'>
								<span @click='showBackdate'>Date: <b>{{backdateManualResult.toISOString().split('T')[0]}}</b></span><div class='miniButton neutral' @click='showBackdate'>Change</div>
							</div>
							<span class='manualScore' v-for='(formula, index) in compositeOutcome.def.sections.map(section=>section.elements.filter(element=>element.type==="formula")).flatten()' :key='index'>
								{{formula.desc}}: <b>{{manualResult[formula.id]}}</b><br />
							</span><br />
							By clicking <b>Record</b> below, you confirm that this is correct and authorized by <b>{{$store.state.profile.user.firstname}} {{$store.state.profile.user.lastname}}.</b>
						</div>
						<div class='iconTextButton neutral' @click='showManual'>
							<svg class='smallIcon'>
								<use xlink:href='#redo' />
							</svg>
							<span class='label'>Redo Questionnaire</span>
						</div>
					</div>

				</div>
			</template>

		</Scroll>		

	</div>

	<div v-if='compositeOutcome && !manualResult' class='previewPanel'>
		<div class='previewLabel'><div class='textButton tagAcc'><span class='label'>Preview ONLY</span></div></div>
		<Scroll>
			<div class='previewNotes' v-if='visibleNotes' v-html='notes' />			
			<OutcomePreview :def='compositeOutcome.def' class='preview' :preview='true' />
		</Scroll>
	</div>			

	<div class='modalControls'>
		<div class='iconTextButton' @click='$emit("close")' >
			<svg class='smallIcon'>
				<use xlink:href='#cancel' />
			</svg>
			<span class='label'>Cancel</span>
		</div>
		<!-- dont bother with the computed function here just put the logic in the template -->
		<div v-if='canSend' class='iconTextButton success' @click='save'>
			<template v-if='delivery==="intervals"'>
				<svg class='smallIcon'><use xlink:href='#check' /></svg>
				<span class='label'>{{$t('elements.buttons.saveSchedule')}}</span>
			</template>
			<template v-else-if='delivery==="once"'>
				<svg class='smallIcon'><use xlink:href='#email' /></svg>
				<span class='label'>{{$t('elements.buttons.email')}}</span>
			</template>
		</div>		
		<div v-else-if='manualResult && delivery === "manual"' @click='saveManual' class='iconTextButton success'>
			<svg class='smallIcon'><use xlink:href='#check' /></svg>
			<span class='label'>Record {{compositeOutcome.savename}} for {{compositeClient.firstname}} {{compositeClient.lastname}}</span>
		</div>
	</div>

	<transition name='fade'><Loading v-show='working' /></transition>

</div>
</template>

<script>

import { cloneDeep } from 'lodash'
import { fill } from '@/utilities'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import { outcomesApi } from '@/services/api/modules/clinician/outcomes'
import { clientOutcomesApi } from '@/services/api/modules/clinician/clientOutcomes'
import { smallModalMixin, largeModalMixin } from '@/components/common/mixins/modal'

import SelectInput from '@/components/common/SelectInput'
import Loading from '@/components/common/Loading'
import Scroll from '@/components/common/Scroll'
import CancelButton from '@/components/common/buttons/Cancel'
import CheckButton from '@/components/common/buttons/Check'
import RichText from '@/components/common/RichText'
import PickEndDate from '@/components/common/PickEndDate'
import PickReminders from '@/components/common/PickReminders'

import ClientChooser from '@/components/clinician/clients/Chooser'
import OutcomeChooser from '@/components/clinician/clients/modals/OutcomeChooser'
import Client from '@/components/clinician/clients/modals/Client'

import OutcomePreview from '@/components/clinician/outcomes/engine/render'
import Manual from './Manual'
import WarnExists from './WarnExists'
import Backdate from './Backdate'

export default {
	name: 'AssignOutcomeToClient',
	mixins: [smallModalMixin, largeModalMixin],
	components: { Loading, Scroll, SelectInput, CheckButton, PickEndDate, PickReminders, CancelButton, RichText, OutcomePreview },
	props: ['client','outcome','cb'], // { id, firstname, lastname, email } and { id, title }
	data() { return { 
		innerClient: null,
		innerOutcome: null,
		delivery: null,
		notes: null,
		intervalType: null,
		expiresOn: null,
		reminders: null,
		working: false,
		manualResult: null,
		backdateManualResult: null
	}},
	computed: {
		canSend() {			
			return (
				(this.compositeOutcome && this.compositeClient && this.expiresOn && this.intervalType && this.delivery === 'intervals') || 
				(this.compositeOutcome && this.compositeClient && this.delivery === 'once')
			)
		},
		canDeliver() {
			return (this.client || this.innerClient) && (this.outcome || this.innerOutcome)
		},
		compositeClient() { return this.client || this.innerClient },
		compositeOutcome() { return this.outcome || this.innerOutcome },
		strChooseDeliver() {
			return fill(this.$t('views.assignOutcome.chooseDelivery.p1'), { outcome: this.compositeOutcome.savename, client: this.compositeClient.firstname+' '+this.compositeClient.lastname })
		},
		visibleNotes() {
			return this.notes && this.notes.replace(/<[^>]+>/g, '').trim()
		},
	},
	methods: {
		close(e) { 
			this.$emit('close')
		},
		chooseClient() {
			this.showMediumModal(ClientChooser, { fn: client=>this.innerClient=client /*, emailRequired: true */ }) 
		},
		chooseOutcome() {
			// for future trav -- move this stuff into the api helper for all api endpoints
			const fn = async outcomeId=>{ 
				/*
				if (['VAS','NRS','RMDQ','QuickDASH','UEFI','LEFS'].includes(outcomeId)) {
					let mod
					try {
						mod = await import(`@/components/clinician/outcomes/stockDefs/${outcomeId}.${this.$store.state.profile.lang}.json`)
					} catch (err) {
						mod = await import(`@/components/clinician/outcomes/stockDefs/${outcomeId}.en.json`)
					}
					const def = cloneDeep(mod.default)
					this.innerOutcome= { type: outcomeId, savename: def.title, def }
				} else {
				*/
					this.working = true
					outcomesApi.touch('fetch', { id: outcomeId })
						.then(([err, result]) => {
							if (!err) {
								if (outcomeId == +outcomeId) { // quick check if is stock or not
									this.innerOutcome = { type: 'custom', ...result }	
								} else {
									this.innerOutcome = { type: outcomeId, savename: result.title, def: result }
								}
								//console.log(this.innerOutcome)
							}
						})
						.finally(() => this.working = false)
			//	}
			}
			this.showMediumModal(OutcomeChooser, { fn }) 
		},
		scrollToStep() {
			this.$nextTick(()=>{
				const nodes = this.$el.querySelectorAll('.step')
				const step = nodes[nodes.length- 1]
				step.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
			})			
		},
		async checkExisting(type) {
			this.working = true
			let payload, route
			console.log(this.compositeOutcome)
			if (this.compositeOutcome.type==='custom') {
				route='customDefExists'
				payload = { defId: this.compositeOutcome.id, clientId: this.compositeClient.id }				
			} else {
				route='stockExists'
				payload = { outcomeType: this.compositeOutcome.type, clientId: this.compositeClient.id }
			}
			const [err, result] = await clientOutcomesApi.touch(route, payload)
			if (!err && result.exists) {
				this.showSmallModal(WarnExists, {
					type: this.compositeOutcome.type,
					client: this.compositeClient,
					fnInspectClient: (clientId) => {
						this.$modal.hideAll()
						this.$store.dispatch('manageClients/setOutcomesView')
							.then(()=>this.$router.replace({ name: 'clients', params: { clientId }}).catch(()=>{}))	
					},
					fnCanceled: () => this.$modal.hideAll()
				}, 650)
			}
			this.working = false
		},
		async save() {
			const fn = async () => {
				this.working = true
				const payload = { 
					type: this.compositeOutcome.type, 
					clientId: this.compositeClient.id,
					expiresOn: this.delivery === 'intervals' ? this.expiresOn : dayjs().add(3, 'month').utc(true).format(), 
					reminders: this.delivery === 'intervals' ? this.reminders || [] : [dayjs().utc(true).format()],
					instructions: this.notes,
					remoteTime: dayjs().utc(true).format()
				}
				if (this.compositeOutcome.type === 'custom') payload.defId = this.compositeOutcome.id
				const [err] = await clientOutcomesApi.touch('create', payload)
				if (!err) {
					// this.$store.dispatch('clients/incOutcomeCount', this.clientId)
					// this.$store.dispatch('manageClientOutcomes/fetchTop')
					if (typeof this.cb === 'function') this.cb({ client: this.compositeClient, outcome: this.compositeOutcome })
					if (this.sendIntervals) this.$store.dispatch('flash/showAction', 'scheduleStarted')
					else this.$store.dispatch('flash/showAction', { code: 'outcomeAssigned', vars: { client: this.compositeClient.firstname + ' ' + this.compositeClient.lastname }})
				}
				this.working = false
				this.$emit('close')		
			}
			if (
				this.compositeClient.email && 
				this.compositeClient.portalKey && 
				!this.compositeClient.emailErr
			) await fn()
			else this.showSmallModal(Client, {
				client: this.compositeClient, 
				emailRequired: true,
				onSave: fn
			})	
		},
		async saveManual() {
			this.working = true
			const payload = { 
				type: this.compositeOutcome.type, 
				clientId: this.compositeClient.id,
				expiresOn: dayjs().add(3, 'month').utc(true).format(), 
				reminders: [],
				remoteTime: dayjs().utc(true).format()
			}
			if (this.compositeOutcome.type === 'custom') payload.defId = this.compositeOutcome.id
			const [err1, result] = await clientOutcomesApi.touch('create', payload)
			const [err2] = await clientOutcomesApi.touch('createResult', { id: result.id, result: this.manualResult, backdate: this.backdateManualResult })
			if (!err1 & !err2) {
				this.$store.dispatch('manageClients/setOutcomesView')
					.then(()=>this.$router.replace({ name: 'clients', params: { clientId: this.compositeClient.id }}))
					.catch(()=>this.$store.dispatch('manageClientOutcomes/fetchTop'))
				this.$store.dispatch('flash/showAction', { code: 'outcomeAssigned', vars: { client: this.compositeClient.firstname + ' ' + this.compositeClient.lastname }})
			}
			this.working = false
			this.$emit('close')	
		},
		showManual() {
			this.showLargeModal(
				Manual, 
				{ 
					def: this.compositeOutcome.def, client: this.compositeClient,
					fn: (result, backdate) => {
						this.manualResult = result
						this.backdateManualResult = new Date()
					},
					sticky: true
				}, 
				'800px', 
				{ 'before-close': () => {
					if (!this.manualResult) this.delivery=null
				}}
			)
		},
		showBackdate() {
			this.showSmallModal(Backdate, { fn: backdate=> {
				this.backdateManualResult=backdate
			}}, '742px')			
		}
	},
	watch: {
		delivery(v) {
			this.scrollToStep()
			if (v==='manual') this.showManual()
			else this.manualResult = null
		},
		expiresOn() {
			this.scrollToStep()
		},
		canDeliver(a) {
			if (a) this.checkExisting()
		}
	}, 
	mounted() {
		window.addEventListener('hashchange', this.close) 
	},
	beforeDestroy() {
		window.removeEventListener('hashchange', this.close) 
	}
}
</script>

<style lang='scss'>
.assignOutcomeToClient {
	@include fill;
	display: grid;
	grid-template-rows: 1fr auto;
	background: $color-neutral-accent;

	.contentPanel {
		position: relative;
		.scrollContent { padding: $size-gutter * 4; }
		&.previewOpen .step { margin-right: calc(35% + 47px); }
	}

	.step {
		background: #fff;
		border: 1px solid $color-neutral-shadow;
		padding: $size-gutter * 4;
		text-align: left;
		box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);
		position: relative;

		.stepHeader {
			font-size: $size-font-large;
			font-weight: bold;
			margin-bottom: $size-gutter * 4;
			> span { color: $color-primary-accent; margin-left: $size-gutter; }
		}

		&+.step { margin-top: $size-gutter * 4; }
	}

	.previewPanel {
		position: absolute;
		top: $size-gutter * 4;
		right: $size-gutter * 4;
		width: 35%;
		height: calc(100% - 116px);
		border: 1px solid $color-neutral-shadow;
		background: #fff;
		box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);

		.previewNotes {
			background: $color-focus;
			margin: $size-gutter * 4;
			margin-bottom: $size-gutter * 2;
			padding: $size-gutter * 2;
			@include rich;
			padding-top: 12px;
			padding-bottom: 13px;			
		}		

		.previewLabel {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 20;
			height: $size-control-height;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.preview {
			padding: 0;
			> div { border: none; }
			.submitButton { display: none; }
		}

		.controlBar { display: none; }
	}

	.chooseClientWrapper {
		display: flex;
		align-items: center;
		> div { flex: 1; }
		.client {
			font-size: $size-font-large;
			line-height: $size-lineHeight-standard;						
			margin-right: $size-gutter * 2;
			> span { font-size: $size-font-standard; display: block; margin-top: 3px; }
		}
		.chooseClientButton { border: 1px solid $color-neutral-shadow; height: $size-control-height + 2; }
	}

	.chooseOutcomeWrapper {
		display: flex;
		align-items: center;
		> div { flex: 1; }
		.outcome {
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;						
			margin-right: $size-gutter * 2;
			> span { font-size: $size-font-standard; display: block; margin-top: 3px; }
		}
		.chooseOutcomeButton { border: 1px solid $color-neutral-shadow; height: $size-control-height + 2; }
	}	

	.chooseDelivery { 
		padding-top: $size-gutter * 4;

		.label {
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			b { color: $color-primary-accent; }
			margin-bottom: $size-gutter * 4;
		}

		.options {
			font-size: $size-font-large;
			> div {
				display: flex;
				align-items: center;
				margin-right: $size-gutter * 4;
				margin-bottom: $size-gutter * 2;
				cursor: pointer;
				&.selected > div { font-weight: bold; color: $color-primary-accent; }
				.checkButton { margin-right: $size-gutter * 3; pointer-events: none; }
				> div span { 
					display: block;
					font-size: $size-font-standard;
					line-height: $size-lineHeight-standard;
					color: $color-black;
					font-weight: normal;
					margin-top: 3px;
				}
			}
		}
	}

	.deliveryDates {
		.dateInputs {
			display: flex;
			align-items: center;
			.days {
				margin-right: $size-gutter * 2;
				width: 350px;
			}
			.until {
				font-size: $size-font-standard;
				margin-right: $size-gutter * 2;
			}
			.pickEndDate { 
				input { width: 150px; }
			}
		}

		.label {
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			b { color: $color-primary-accent; }
			margin: $size-gutter * 4 $size-gutter * 2;
		}
	}

	.notes {
		.label {
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			b { color: $color-primary-accent; }
			margin-bottom: $size-gutter * 4;
		}
		.richText {
			border: 1px solid $color-neutral-shadow;
			width: 100%;
		}
	}

	.manual {
		.label {
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			b { color: $color-primary-accent; }
			display: flex; 
			> div { flex: 1;}
		}
		.manualDate { display: flex; align-items: center; margin-bottom: $size-gutter * 2; .miniButton { margin-left: $size-gutter; border: 1px solid $color-neutral-shadow; } b { text-decoration: underline; cursor: pointer;  }}
		.manualScore {  }
		.iconTextButton { border: 1px solid $color-neutral-shadow; height: $size-control-height + 2; margin-left: $size-gutter * 2; }
	}
}
</style>
