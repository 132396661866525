<template>
<div class='formula'>
	<div class='question'>
		<div class='desc' v-html='def.desc' />
		<div v-if='typeof value === "number"' class='score'>{{value}}</div>
	</div>		
</div>
</template>

<script>
import { evaluate } from 'mathjs'

export default {
	name: 'Formula',
	components: {},
	props: ['value','def','result'],
	computed: {
		innerResult() {
			const o = { ...this.result }
			delete o[this.def.id]
			return o
		}
	},
	methods: {
		calc() {
			let v 
			try {
				v = evaluate(this.def.expr, this.innerResult)
				v = Math.round(v * 100) / 100
				if (!isFinite(v)) v = undefined
			} catch(err) {
				v = undefined
			}
			this.update(v)
		},
		update(v) { this.$emit('input', v) }
	},
	watch: {
		innerResult: {
			handler() { this.calc() },
			deep: true
		}
	},
	mounted() { this.calc() } // in case there is nothing else in the section
}
</script>

<style lang='scss'>
.customOutcomeRender .formula {
	.question { 
		display: flex;
		align-items: center;
		.desc { }
		.score { 
			padding: $size-gutter * 3; 
			margin-left: $size-gutter * 3;
			font-size: $size-font-large; 
			font-weight: bold; 
			color: $color-primary-accent; 
			background: $color-focus; 
			border-radius: 6px; 
		}
	}
}
</style>