<template>
<div class='clientsChooser'>

	<div class='topPanel'>
		<SearchInput 
			v-model='search'
			@input='fetchSearch'
			@submit='fetchSearchImmediate'
			:placeholder='$t("views.clientsManager.phSearchClients")'
		/>
	</div>

	<div class='contentPanel'>
		<RecycleScroller
			:items='items'
			:item-size='40'
			:buffer='500'
			class='recycleScroller'
		>
			<template slot='before'>
				<div class='controls'>
					<div>
						<EnabledButton 
							@click='addClient'
							:svg='"#plus"' :label='$t("elements.buttons.client")' 
							class='neutral tagPrimary addClientButton' 
						/>	
					</div>
					<div>
						<div class='clientMax'>
							<span>Clients: {{$store.state.saveClients.max}}</span>
							<span v-if="teams.length">
								&nbsp;&nbsp;({{teams.map(t=>t.title).join(", ")}})
							</span>
						</div>
						<!--
							<div v-if='teams.length' class='teams'>
								<span>Shared:</span>
								<div>
									<div 
										v-for='(team, index) in teams'
										:key='index'
										@click='toggleTeam(team.id)'>
										<CheckButton :checked='teamId===team.id' />
										<span class='label'>{{team.title}}</span>
									</div>	
								</div>
							</div>
						-->
					</div>
				</div>
			</template>		
			<template v-if='total' slot='sticky'>
				<TableColumns 
					:columns='[{
						label: $t("elements.cols.client"),
						flex: 1
					},{
						label: "Last Seen by",
						flex: 1
					}]'
				/>
			</template>
			<template v-slot='{ item }'>
				<ListItem :item='item' :itemKey='item.id' @click='cb' />
			</template>
			<template slot='after'>
				<AtBottom :action='fetchNext' />
			</template>
		</RecycleScroller>

		<transition name='fade'><Loading v-show='working' /></transition>
	</div>

	<div class='modalControls'>
		<CancelButton @click='$emit("close")' />		
	</div>	

</div>
</template>

<script>
import Cookies from 'js-cookie'	
import { debounce } from 'lodash'
import { UI } from '@/utilities'
import { smallModalMixin } from '@/components/common/mixins/modal'
import { clientsApi } from '@/services/api/modules/clinician/clients'
import RecycleScroller from '@/libs/vueVirtualScroller/components/RecycleScroller'
import ResultInfo from '@/components/common/ResultInfo'
import AtBottom from '@/components/common/AtBottom'
import Loading from '@/components/common/Loading'
import CheckButton from '@/components/common/buttons/Check'
import CancelButton from '@/components/common/buttons/Cancel'
import SearchInput from '@/components/common/SearchInput'
import TableColumns from '@/components/common/TableColumns'
import EnabledButton from '@/components/common/buttons/Enabled'
import ClientModal from '../modals/Client'
import ListItem from './ListItem'

export default {
	name: 'ClientsChooser',
	mixins: [smallModalMixin],
	components: {
		RecycleScroller,
		ResultInfo,
		AtBottom,
		ListItem,
		SearchInput,
		TableColumns,
		CheckButton,
		CancelButton,
		EnabledButton,
		Loading
	},
	props: ['fn', 'emailRequired'],
	data: () => ({
		search: '',
		searched: '',
		working: false,
		total: 0,
		items: [],
		sortCol: null,
		sortDir: 'desc',
		fetchSearch: null,
		teamId: null
	}),
	computed: {
		sort() { 
			return {
				col: this.sortCol,
				dir: this.sortDir,
			}
		},		
		teams() {
			return this.$store.state.profile.teams ? Object.values(this.$store.state.profile.teams).filter(t=>t.teamClients) : []
		}
	},
	methods: {
		fetchSearchImmediate() {
			this.fetchSearch.flush()
		},
		fetchTop(search) {
			this.working = true
			const filter = { search, archived: false }
			if (search && this.teams.length) filter.teamIds = this.teams.map(t=>t.id).join(",")
			return clientsApi.touch('fetchClients', filter).then(([err, result]) => {
				if (!err) {
					this.items = result.items
					this.total = result.total
					this.searched = search
				}
			})
			.finally(() => this.working = false)
		},
		fetchNext() {
			if (this.items.length < this.total) {
				this.working = true
				const filter = { search: this.search, archived: false, skip: this.items.length }
				if (this.search && this.teams.length) filter.teamIds = this.teams.map(t=>t.id).join(",")
				return clientsApi.touch('fetchClients', filter).then(([err, result]) => {
					if (!err) this.items.push(...result.items)
				})
				.finally(() => this.working = false)
			}
		},
		toggleTeam(teamId) {
			this.teamId = this.teamId === teamId ? null : teamId 
			this.ui.set('saveClientsTeamId', teamId)
			this.fetchTop()
		},
		addClient() {
			this.showSmallModal(ClientModal, {
				emailRequired: true,
				onSave: (client)=>{
					this.$emit('close')
					this.fn(client)
				}
			})
		},
		cb(client) {
			if (this.emailRequired && (!client.email || !client.portalKey)) {
				this.showSmallModal(ClientModal, {
					client,
					emailRequired: true,
					onSave: (client)=>{
						this.$emit('close')
						this.fn(client)
					}
				})
			} else {
				this.$emit('close')
				this.fn(client)
			}
		}
	},
	mounted() {
		this.fetchSearch = debounce(function(search) { this.fetchTop(search) }.bind(this), 1000, { leading: false, trailing: true })
		this.ui = new UI(this.$store.state.profile.user.id)
		const teamId = this.ui.get('saveClientsTeamId')
		if (this.teams.find(t=>t.id===teamId)) this.teamId = teamId
		this.fetchTop()
	}
}
</script>

<style lang='scss'>
.clientsChooser {
	position: relative;
	@include fill;
	display: grid;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;
	border-right: 1px solid $color-neutral-shadow;

	.topPanel {
		margin:  $size-gutter * 2;
		margin-bottom: 0;
		border: 1px solid $color-neutral-shadow;
		background: $color-neutral-shadow;
	}

	.contentPanel {
		position: relative;


		.controls {
			display: flex;
			align-items: flex-start;
			padding: $size-gutter * 2;
			/*
			padding-top: $size-gutter * 3;
			padding-right: $size-gutter * 2;
			padding-left: $size-gutter * 2;
			*/
			min-height: 100px;
			> div:first-child { flex: 0; }
			> div:last-child { flex: 1; }

			.addClientButton {
				height: 24px;
			}

			.clientsFound {
				font-size: $size-font-standard;
				padding-left: $size-gutter * 2 + 1;	
				white-space: nowrap;		
				display: flex;
				align-items: center;
				height: 24px;
			}


			.clientMax {
				height: 24px;
				display: flex;
				align-items: center;
				justify-content: right;
				font-size: $size-font-standard;
				padding-right: $size-gutter * 2;
			}

		}	

		.tableColumns { margin: 0 $size-gutter * 2; }
			
	}

	.loading {
		background: rgba($color-neutral-panel, 0.3);
	}
}
</style>
