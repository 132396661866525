<template>
<SmallModal class='scheduleDelivery' @close='$emit("close")' :showCancel='false'>
	<template v-slot:title>Schedule Delivery Dates</template>
	<template v-slot:message>
		<div class='dateInputs'>
			<!-- this select should probably be in its own component -->
			<div class='days inputWrapper'>
				<SelectInput v-model='intervalType'>
					<option disabled selected value=''>{{$t('views.assignOutcome.chooseDates.phDates')}}</option>
					<option value='custom'>{{$t('views.assignOutcome.chooseDates.optSpecific')}}</option>
					<option value='every'>{{$t('views.assignOutcome.chooseDates.optDaily')}}</option>
					<option value='alt'>{{$t('views.assignOutcome.chooseDates.optAltDays')}}</option>
					<optgroup :label='$t(`views.assignOutcome.chooseDates.optWeekly`)'>
						<option value='Mo'>{{$t('views.assignOutcome.chooseDates.weekdays.mo')}}</option>
						<option value='Tu'>{{$t('views.assignOutcome.chooseDates.weekdays.tu')}}</option>
						<option value='We'>{{$t('views.assignOutcome.chooseDates.weekdays.we')}}</option>
						<option value='Th'>{{$t('views.assignOutcome.chooseDates.weekdays.th')}}</option>
						<option value='Fr'>{{$t('views.assignOutcome.chooseDates.weekdays.fr')}}</option>
						<option value='Sa'>{{$t('views.assignOutcome.chooseDates.weekdays.sa')}}</option>
						<option value='Su'>{{$t('views.assignOutcome.chooseDates.weekdays.su')}}</option>
					</optgroup>
					<optgroup :label='$t(`views.assignOutcome.chooseDates.optAltWeeks`)'>
						<option value='altMo'>{{$t('views.assignOutcome.chooseDates.weekdays.mo')}}</option>
						<option value='altTu'>{{$t('views.assignOutcome.chooseDates.weekdays.tu')}}</option>
						<option value='altWe'>{{$t('views.assignOutcome.chooseDates.weekdays.we')}}</option>
						<option value='altTh'>{{$t('views.assignOutcome.chooseDates.weekdays.th')}}</option>
						<option value='altFr'>{{$t('views.assignOutcome.chooseDates.weekdays.fr')}}</option>
						<option value='altSa'>{{$t('views.assignOutcome.chooseDates.weekdays.sa')}}</option>
						<option value='altSu'>{{$t('views.assignOutcome.chooseDates.weekdays.su')}}</option>
					</optgroup>
				</SelectInput>
			</div>
			<div class='until'>Until</div>
			<div class='pickEndDate inputWrapper'>
				<PickEndDate 
					v-model='iExpiresOn' 
					:rows='1'
					:columns='2'
					:placeholder='$t(`views.assignOutcome.chooseDates.phEndDate`)'
					popOverPlace='bottom' 
				/>
			</div>
		</div>
		<PickReminders 
			v-model='iReminders' 
			:intervalType='intervalType'
			:expiresOn='iExpiresOn'
		/>
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton' @click='$emit("close")'>
			<span class='label'>Cancel</span>
		</div>
		<div v-if='canSave' class='iconTextButton success' @click='onDone'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'>Save and Schedule</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import dayjs from 'dayjs'
import SmallModal from '@/components/common/modals/SmallModal'
import SelectInput from '@/components/common/SelectInput'
import PickReminders from '@/components/common/PickReminders'
import PickEndDate from '@/components/common/PickEndDate'

export default {
	name: 'OutcomeExists',
	components: {SmallModal, SelectInput, PickReminders, PickEndDate},
	props: ['fn', 'disabled', 'reminders', 'expiresOn'],
	data: () => ({ 
		intervalType: null,
		iReminders: null,
		iExpiresOn: null
	}),
	computed: {
		canSave() {
			return JSON.stringify(this.reminders)+JSON.stringify(this.expiresOn) !== JSON.stringify(this.iReminders)+JSON.stringify(this.iExpiresOn)
		}
	},
	methods: {
		onDone() {
			this.fn(this.iReminders, this.iExpiresOn)
			this.$emit('close')
		}
	},
	created() {
		this.iReminders = this.reminders
		this.iExpiresOn = this.expiresOn
	}
}
</script>

<style lang='scss'>
.scheduleDelivery {

		.dateInputs {
			display: flex;
			align-items: center;
			margin-bottom: $size-gutter * 4;
			.days {
				margin-right: $size-gutter * 2;
				width: 350px;
			}
			.until {
				font-size: $size-font-standard;
				margin-right: $size-gutter * 2;
			}
			.pickEndDate { 
				input { width: 150px; }
			}
		}

}
</style>