<template>
<div class='outcomeItem'>
	<div 
		ref='menu'
		class='scrollMenu' 
		:style='`transform: translateY(${menuTop}px)`'
	>
		<div class='controls'>

			<div class='controlBar'>
				<EnabledButton @click='newItem' :disabled='outcomeId==="new"&&!unsaved' :svg='"#plus"' :label='$t("elements.buttons.new")' class='neutral tagPrimary' />
				<EnabledButton @click='save' :disabled='!unsaved' :svg='"#save"' :label='$t("elements.buttons.save")' class='neutral tagSuccess' />
				<EnabledButton @click='copy' :disabled='!this.attrs.id && !isStock' :svg='"#copy"' label='Duplicate' class='neutral' />
			</div>

			<div class='controlBar'>
				<EnabledButton @click='assign' :disabled='unsaved || !$store.state.profile.user.allowPHI || !outcomeId || outcomeId === "new"' :svg='"#email"' label='Send' class='neutral' />	
				<EnabledButton :disabled='!outcomeId' :svg='"#print"' :label='$t("elements.buttons.pdf")' class='neutral' @click='print' />			
				<DeleteButton :disabled='!canDestroy' @click='destroy' /> 
			</div>

		</div>
		<div class='tabs' v-if='outcomeId'>
			<div class='iconTextButton' @click='tab="edit"' :class='{ selected: tab==="edit" }'>
				<svg class='smallIcon'>
					<use xlink:href='#edit' />
				</svg>
				<span class='label'>Edit</span>
			</div>
			<div class='iconTextButton' @click='tab==="edit" && $refs.edit.validate() ? tab="preview" : null' :class='{ selected: tab==="preview" }'>
				<svg class='smallIcon'>
					<use xlink:href='#search' />
				</svg>
				<span class='label'>Preview</span>
			</div>
			<CloseButton @click='$router.push({ params: { outcomeId: null }}).catch(()=>{})' />
		</div>
	</div>
	<transition name='scroll'>
		<div v-show='showMenuTab' class='blockButton neutral menuTrigger' @click='onClickMenuTab'>
			<svg class='smallIcon'>
				<use xlink:href='#caretDown' />
			</svg>
		</div>
	</transition>		
	<div v-if='outcomeId' class='content'>
		<Scroll @diff='onScrollDiff' ref='scroll'>
			
			<div class='savename'>
				<span>{{attrs.savename || attrs.def.title || "Untitled"}}</span>
				<div 
					v-if='attrs.id && this.canUpdate'
					class='renameButton miniButton silver' 
					@click.stop='$emit("rename", attrs)'
				>{{ $t('elements.buttons.rename') }}</div>	
				<!--<span v-if='updated'>{{updated}}</span>-->
			</div>
			<OutcomeEditor v-if='tab==="edit"' :def='attrs.def' ref='edit' :key='renderKey' @startDrag='autoScroll=true' @stopDrag='autoScroll=false' />
			<OutcomePreview v-else-if='tab==="preview"' :def='attrs.def' :result='result' @scrollToTop='$refs.scroll.scrollToTop(); showMenu()' @submit='onSubmit' />
		</Scroll>
	</div>
	<div v-else class='tutorial'>
		<div class='tutorialButton' @click='newItem'>
			<div class='border'>
				<svg class='giantIcon'>
					<use xlink:href='#plus' />
				</svg>
			</div>
			<span class='label'>Outcome</span>
		</div>
	</div>
	<transition name='fade'><Loading v-show='working' /></transition>			
</div>
</template>

<script>
import printJS from 'print-js-updated'
import { pick, pickBy, throttle, cloneDeep } from 'lodash'
import { dateTime } from '@/components/common/mixins/dateTime'
import { outcomesApi } from '@/services/api/modules/clinician/outcomes'
import { authApi } from '@/services/api/modules/auth'
import { smallModalMixin, largeModalMixin } from '@/components/common/mixins/modal'
import { pollDownload } from '@/components/common/mixins/pollDownload'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import ConfirmModal from '@/components/common/modals/Confirm'	
import TextInputModal from '@/components/common/modals/TextInput'
import CloseButton from '@/components/common/buttons/Close'
import Loading from '@/components/common/Loading'
import EnabledButton from '@/components/common/buttons/Enabled'
import DeleteButton from '@/components/common/buttons/Delete'
import Scroll from '@/components/common/Scroll'
import Alert from '@/components/common/modals/Alert'

import OutcomeEditor from './engine/design'
import OutcomePreview from './engine/render'

import Assign from '@/components/clinician/AssignOutcomeToClient'

export default {
	name: 'OutcomeItem',
	components: { Loading, EnabledButton, DeleteButton, CloseButton, Scroll, OutcomeEditor, OutcomePreview },
	mixins: [smallModalMixin, largeModalMixin, dateTime, pollDownload],
	props: ['outcomeId','folders'],
	data() { return {
		menuTop: 0,
		showMenuTab: false,
		ignoreScrollEvents: false,
		scrollTop: 0,
		tab: 'edit',
		working: false,
		saved: '',
		attrs: {},
		savename: '',
		result: {},
		renderKey: 0,
		autoScroll: false,
		endY: 0,
		scrollInterval: null
	}},
	computed: {
		isStock() {
			return ['VAS','NRS','RMDQ','QuickDASH','UEFI','LEFS','SF36','DASS21','KOOS'].includes(this.outcomeId)
		},
		unsaved() {
			return this.saved!==JSON.stringify(this.attrs.def) && !this.working && this.outcomeId
		},
		canUpdate() {
			if (this.outcomeId==='new') return true
			return this.isFolderWriteable(this.attrs.folderId)
		},
		canDestroy() {
			return this.canUpdate && this.outcomeId!=='new'
		},
		updated() {
			return this.attrs.updated ? this.dateTime(this.attrs.updated) : null	
		}
	},
	methods: {
		onMouseMove: function(e) {
			this.endY = e.clientY || e.touches[0].clientY
		},		
		onSubmit() {
			this.showSmallModal(Alert, { title: 'Looks great!', message: 'Your Outcome Meausure is working perfectly!' })
		},		
		isFolderWriteable(folderId) {
			const type = this.folders[folderId] ? this.folders[folderId].type : null
			return (
				type === 'user' ||
				(type === 'org' && this.$store.state.profile.user.orgOutcomes === 'readWrite') ||
				(type === 'team' && this.$store.state.profile.teams[this.folders[folderId].teamId].teamOutcomes === 'readWrite')
			)			
		},		
		onScrollDiff({ diff, el }) {
			this.scrollTop = el.scrollTop
			if (this.ignoreScrollEvents) return
			// bounce
			if (el.scrollTop < 0) return this.menuTop = 0
			if (el.scrollTop > el.scrollHeight - el.offsetHeight) return //this.menuTop = -h
			// cap limits
			const h = this.$refs.menu.offsetHeight + 14
			if (this.menuTop > 0) return this.menuTop = 0
			else if (this.menuTop < -h) return this.menuTop = -h
			// calc tab
			if (!this.showMenuTab && this.menuTop === -h) this.showMenuTab = true
			else if (this.showMenuTab && this.menuTop > -h) this.showMenuTab = false
			// calc pos
			if (diff < 0 && Math.abs(this.menuTop) < h) {
				this.menuTop+=diff
			} else if (diff > 0 && this.menuTop < 0) {
				this.menuTop = Math.min(0, this.menuTop + diff)
			}
		},
		onClickMenuTab() {
			this.showMenuTab=false
			this.menuTop = 0
			this.$refs.scroll.setScrollOffset(-this.$refs.menu.offsetHeight)
		},	
		startAutoScroll() {
			const gutter = 150
			const accel = 8
			const scrollEl = this.$refs.scroll.scrollEl
			const box = scrollEl.parentNode.getBoundingClientRect()
			const getOffset = () => {
				if (this.endY > box.bottom - gutter) return (this.endY - box.bottom + gutter) / accel
				else if (this.endY < box.top + gutter) return -(box.top + gutter - this.endY) / accel
				else return 0
			}
			const repeat = () => {
				const offset = getOffset()
				scrollEl.scrollTop += offset
				this.scrollInterval = requestAnimationFrame(repeat)
			}
			this.scrollInterval = requestAnimationFrame(repeat)
		},
		hideMenu() {
			const h = this.$refs.menu.offsetHeight	
			this.menuTop = -h - 14
			this.showMenuTab = true		
		},	
		showMenu() {
			this.menuTop = 0
			this.showMenuTab = false	
		},
		initDef() {
			const attrs = {
				id: null,
				folderId: null,
				created: null,
				updated: null,
				savename: '',
				def: {
					title: '',
					instructions: '',
					autoNumber: true,
					sections: [{ elements: [] }]
				}
			}
			this.$set(this, 'attrs', attrs)
			this.$nextTick(()=>{
				if (this.$refs.edit) this.$refs.edit.addElement(0, 'checkbox')
				this.saved = JSON.stringify(this.attrs.def)
			})
		},		
		parseResult(outcome) {
			this.$delete(this.attrs, 'id')
			this.$delete(this.attrs, 'type')
			Object.assign(this.attrs, pick(outcome, ['id', 'folderId', 'created', 'updated', 'savename', 'def', 'type']))
			if (!this.attrs.type) this.attrs.type='custom'
			this.saved = JSON.stringify(this.attrs.def)
			this.result = {}
		},
		parseDefForSave() {
			const d = this.attrs.def
			const def = {}
			if (d.title) def.title = d.title
			if (d.instructions) def.instructions = d.instructions
			if (d.autoNumber) def.autoNumber = d.autoNumber 
			def.sections = d.sections
				.filter(s=>s.elements.length)
				.map(s=>{
					const sec = {}
					if (s.desc) sec.desc = s.desc
					sec.elements = s.elements
						.map(e=>Object.keys(e)
							.filter(k => e[k] || e[k]===0)
							.reduce((acc, key) => ((acc[key] = e[key]), acc), {})
						)
					return sec
				})
			return def
		},
		newItem() {
			if (this.outcomeId==='new') {
				const fn = () => {
					this.initDef()
					this.renderKey++
					this.$router.push({ params: { outcomeId: 'new' }}).catch(()=>{})				
				}				
				this.showSmallModal(ConfirmModal, {
					title: this.$t('views.confirmExitOutcome.title'),
					message: this.$t('views.confirmExitOutcome.p1'),
					ok: this.$t('elements.buttons.continue'),
					fn
				})
			} else {
				this.$router.push({ params: { outcomeId: 'new' }}).catch(()=>{})	
			}
			this.tab='edit'
			/* note to future trav:
			the only reason we need to force an update via renderkey is because the richtext component doesn't update if there are changes to the value
			when we are updated to tiptap3, renderkey can be removed
			*/
		},
		async fetch() {
			this.working = true
			outcomesApi.touch('fetch', { id: this.outcomeId })
				.then(([err, result]) => {
					if (!err) {
						if (this.isStock) {
							this.initDef()
							const def = result
							this.parseResult({ savename: def.title, def, type: this.outcomeId })
							this.renderKey++
							this.tab='preview'
						} else {
							this.parseResult(result)
							this.renderKey++
						}
					}
				})
				.finally(() => this.working = false)
		},
		async save() {	
			if (this.tab!=='edit') {
				this.tab='edit'
				await this.$nextTick(()=>{})
			}
			if (this.$refs.edit.validate()) {
				const { id } = this.attrs
				if (id && this.canUpdate) {
					this.working = true
					const payload = { id, def: this.parseDefForSave() }
					outcomesApi.touch('update', payload)
						.then(([err, result]) => {
							if (!err) {
								this.parseResult(result)
//								this.$emit('saved')
								this.$store.dispatch('flash/showAction', 'saved')
							}
						})
						.finally(() => this.working = false)		
				} else {
					const fn = savename => {
						this.working = true
						const userRootId = Object.values(this.folders).find(f=>f.type==='user' && !f.parentId).id
						const folderId = this.folders.selected && this.isFolderWriteable(this.folders.selected) ? this.folders.selected : userRootId
						const payload = { folderId, savename, def: this.parseDefForSave() }
						outcomesApi.touch('create', payload)
							.then(([err, result]) => {
								if (!err) {
									this.parseResult(result)
									this.$store.dispatch('flash/showAction', 'saved')
									if (folderId != this.folders.selected) this.$store.dispatch('manageOutcomes/setFolderId', folderId)
									this.$router.push({ params: { outcomeId: result.id }})
									this.$emit('saved')
								}
							})
							.finally(() => this.working = false)
					}
					this.showSmallModal(TextInputModal, {
						title: 'Save Outcome As...',
						placeholder: 'Outcome...',
						value: this.attrs.savename || this.attrs.def.title,
						button: this.$t('elements.buttons.saveAs'),
						maxlength: 100,
						fn
					})
				}	
			}
		},
		async copy() {
			if (this.tab!=='edit') {
				this.tab='edit'
				await this.$nextTick(()=>{})
			}			
			if (this.$refs.edit.validate()) {
				this.working = true
				const userRootId = Object.values(this.folders).find(f=>f.type==='user' && !f.parentId).id
				const folderId = this.folders.selected && this.isFolderWriteable(this.folders.selected) ? this.folders.selected : userRootId
				const savename = this.attrs.savename + ' copy'
				const payload = { folderId, savename, def: this.parseDefForSave() }
				outcomesApi.touch('create', payload)
					.then(([err, result]) => {
						if (!err) {
							this.parseResult(result)
							if (folderId != this.folders.selected) this.$store.dispatch('manageOutcomes/setFolderId', folderId)
							this.$router.push({ params: { outcomeId: result.id }})
							this.$emit('saved')
							if (this.canUpdate) {
								this.$store.dispatch('flash/showAction', 'copied')
							} else {
								this.$store.dispatch('flash/showAction', 'copyPersonal')
							}
						}
					})
					.finally(() => this.working = false)
			}
		},
		destroy() {
			const fn = () => {
				this.working = true
				outcomesApi.touch('destroy', { id: this.attrs.id })
					.then(([err]) => {
						if (!err) {
							this.$emit('saved')
							this.$router.replace({ name: "outcomes" })
						}
					})
					.finally(() => this.working = false)				
			}
			this.showSmallModal(ConfirmDeleteModal, { items: this.attrs.savename, fn })				
		},
		assign() {
			this.showLargeModal(Assign, { outcome: this.attrs, sticky: true })
		},
		async print() {
			if (this.$refs.edit && this.$refs.edit.validate() || !this.$refs.edit) {
				this.working = true
				const payload = {
					outcomeType: this.attrs.type || 'custom',
					def: this.attrs.def
				}
				const outcomeResult = pickBy(this.result) // remove any undefined props
				if (Object.keys(outcomeResult).length) payload.result = 	outcomeResult
				const [err, result] = await outcomesApi.touch('export', payload)
				if (!err) {
					const { url, readyId } = result
					await this.poll(readyId)
					printJS(url)
				}
				this.working = false	
			}
		},
		ping: throttle(()=>authApi.touch('ping'), 1000 * 60 * 5), // avoid logouts while working on outcome
	},
	watch: {
		outcomeId(a, b) {
			if (a !== b && a) {
				if (a==='new') {
					this.initDef()
					this.renderKey++		
				} else {
					this.fetch()
				}
			} else if (!a) {
				this.initDef()
			}
		},
		attrs: {
			handler() { 
				this.ping()
			},
			deep: true
		},
		autoScroll(value) {
			if (value && !this.$store.state.main.mobile) {
				document.addEventListener('dragover', this.onMouseMove)
				this.startAutoScroll()
			} else {
				cancelAnimationFrame(this.scrollInterval)
				document.removeEventListener('dragover', this.onMouseMove)	
			}
		}
	},
	created() { this.initDef() },
	mounted() {
		if (this.outcomeId && this.outcomeId !== 'new') this.fetch()
	}
}
</script>

<style lang='scss'>
.outcomeItem {
	@include fill;
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	overflow: hidden;

	.scrollMenu {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 550;
		width: calc(100% - #{$size-gutter * 2});
		background: $color-neutral-panel;
	}	

	.menuTrigger {
		position: absolute;
		top: 0;
		z-index: 550;
		left: $size-gutter * 2;
		border-top: none;
		border-bottom-right-radius: 7px;
		border: 1px solid $color-neutral-shadow;
		border-top: none;
		box-shadow: 0 3px 5px rgba(0,0,0,0.2);	
	}	

	.controls {
		margin: $size-gutter * 2;
		margin-right: 0;
		border: 1px solid transparent;
		display: grid;
		> div { border: none; 
			> div {
				background: $color-neutral-panel;
				&.disabled { background: $color-neutral-panel !important; }
			}
		}
	}

	.tabs {
		display: flex;
		margin: $size-gutter * 2;
		margin-right: 0;
		margin-bottom: 0;
		cursor: pointer;
		> div:not(.closeButton) { 
			height: $size-control-height + 2px;
			flex: 1; 
			&.selected {
				background: linear-gradient(to bottom, $color-neutral-silver, $color-neutral-panel);
				border: 1px solid $color-neutral-shadow;
				border-bottom: none;
			}
			&:not(.selected) {
				border: 1px solid $color-neutral-shadow;
				border-top: none;
				background: linear-gradient(to bottom, $color-neutral-panel, $color-neutral);
				&:first-child { border-right: none; }
				&:not(:first-child) { border-left: none; }
			}
		}
	}

	.savename { 
		margin-bottom: $size-gutter * 2;
		padding: 0 $size-gutter * 4;
		padding-top: $size-gutter * 2 - 2;
		
		> span:first-child { font-style: italic; color: $color-primary-accent; font-weight: normal; font-weight: bold; }
		.miniButton { border: 1px solid $color-neutral-shadow; margin-left: $size-gutter; }
		font-size: $size-font-standard;
		display: flex;
		align-items: center;
		> span:nth-child(3) { margin-left: auto; }
	}

	.content { position: relative; }	

	.tutorial { 
		display: flex; 
		align-items: center;
		justify-content: center;

		.tutorialButton {
			color: $color-black;
			font-size: $size-font-large;
			text-align: center;
			max-width: 96px;
			width: 100%;
			cursor: pointer;
			padding: 1px;

			.border {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: center;
				max-width: 96px;
				width: 100%;
				padding-top: 100%;
				border-radius: 50%;
				margin-bottom: $size-gutter * 2;
				background: $button-gradient-primary;
				color: $color-white;

				svg {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					transform: scale(0.5);
				}
			}

			&:hover .border {
				background: $color-primary-accent;
			}
		}
	}

	.scrollContent {
		padding: $size-gutter * 2 0;
		> div:first-child { margin-top: $size-control-height * 3 + $size-gutter * 4 + 4; }
	}

}
</style>