<template>
<div class='customOutcomeEditor' :class='{ dragging }'>	
	<div class='outcomeHead'>
		<TextInput 
			v-model='def.title' 
			class='title' 
			placeholder='Outcome Title... (Optional)' 
			maxlength='100' 
		/>
		<div class='autoNumber'>
			<span>Auto-Number</span>
			<DraggableSwitch
				:value='def.autoNumber'
				@input='def.autoNumber=!def.autoNumber'
			/>
		</div>
		<div class='instructions'>
			<RichText 
				v-model='def.instructions' 
				placeholder='Outcome Instructions... (Optional)' 
				:collapseOnInactive='true'
				:maxlength='4000'
			/>
		</div>

		<!--
		<span>Title / Instructions</span>
		<DraggableSwitch
			:value='def.autoNumber'
			@input='def.autoNumber=!def.autoNumber'
		/>
	-->
	</div>
	<div class='sections'>

		<div v-for='(section, i) in def.sections' :key='`section-${i}`' class='section' :class='{ empty: !section.elements.length && i }' ref='sections'>
			
			<div v-if='def.sections.length>1 || section.desc' class='sectionHead'>
				<header>
					<RichText 
						v-model='section.desc' 
						placeholder='Section Description / Instructions... (Optional)' 
						:maxlength='1000'
						class='desc'
					/>
					<CloseButton @click='delSection(i)' />
				</header>
				<!--
				<div v-else-if='section.elements.length'>+ Section Description</div>
			-->
			</div>

			<div class='elements'>
				<draggable 
					:list='section.elements' 
					v-bind='{ animation: 200, ghostClass: "ghost" }'
					handle='.dragHandle'
					@start='onDragStart'					
					@end='onDragEnd'					
					group='elements'
					:delay='200'
					:delayOnTouchOnly='true'
				>
					<div v-for='(element, j) in section.elements' :key='element.id' >
						<component 
							:is='`${element.type[0].toUpperCase()}${element.type.substr(1)}`' 
							:def='element'
							:root='def'
							:sumIds='sumIds'
							:pos='[i,j]'
							:autoNumber='def.autoNumber'
							:class='{ dragging }'
							:active='activeId===element.id'
							:summable='sumIds.includes(element.id)'
							:index='getIndex(i,j)'
							@del='delQuestion'
							@copy='copyQuestion'
							@changeType='changeType'
							@setActive='id=>activeId=id'
							@setSum='setSum'
							@setPrevActive='setPrevActive'
							@setNextActive='setNextActive'
							@blur='activeId=null'
							ref='elements'
						/>
					</div>
				</draggable>
			</div>

			<div class='sectionControls controlBar'>
				<AddElement @add='v=>addElement(i,v)' />
				<div @click='addElement(i, "textBlock")' class='addDivider iconTextButton neutral'>
					<svg class='smallIcon'>
						<use xlink:href='#plus' />
					</svg>
					<span class='label'>Text Block</span>
				</div>				
				<div @click='addElement(i, "formula")' class='addFormula iconTextButton neutral'>
					<svg class='smallIcon'>
						<use xlink:href='#plus' />
					</svg>
					<span class='label'>Formula</span>
				</div>								
			</div>

		</div>

	</div>

	<div v-if='def.sections[def.sections.length-1].elements.length' @click='addSection' class='addSection iconTextButton neutral'>
		<svg class='smallIcon'>
			<use xlink:href='#plus' />
		</svg>
		<span class='label'>Section</span>
	</div>

</div>
</template>

<script>
import { customAlphabet } from 'nanoid'
const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyz', 3)

import { cloneDeep } from 'lodash'
import draggable from 'vuedraggable'

import DraggableSwitch from '@/components/common/DraggableSwitch'
import RichText from '@/components/common/RichText'
import TextInput from '@/components/common/TextInput'
import Alert from '@/components/common/modals/Alert'
import CloseButton from '@/components/common/buttons/Close'

import { smallModalMixin } from '@/components/common/mixins/modal'

import ShortComment from './elements/ShortComment'
import LongComment from './elements/LongComment'
import Checkbox from './elements/Checkbox'
import MultipleChoice from './elements/MultipleChoice'
import Number from './elements/Number'
import NumberSlide from './elements/NumberSlide'
import TextBlock from './elements/TextBlock'
import Formula from './elements/Formula'

import AddElement from './AddElement'


export default {
	name: 'CustomOutcomeEditor',
	components: {
		DraggableSwitch, RichText, TextInput, CloseButton,
		ShortComment, LongComment, Checkbox, MultipleChoice, Number, NumberSlide, TextBlock, Formula, AddElement,
		draggable
	},
	mixins: [smallModalMixin],
	props: {
		def: {
			type: Object,
			default: () => ({})
		}		
	},
	data() { 
		return { 
			dragging: false, 
			activeId: null, 
			sumIds: [],
			index: 0, 
			defaultType: 'checkbox' 
		}
	},
	methods: {
		onDragStart() { 
			this.dragging = true
			this.$emit('startDrag')
		},
		onDragEnd() {
			this.dragging = false
			this.def.sections = this.def.sections.filter(section=>section.elements.length)
			this.$emit('stopDrag')
		//	this.addSection()
		},
		setSum(id) {
			if (this.sumIds.includes(id)) {
				this.sumIds = this.sumIds.filter(v=>v!==id)
			} else {
				this.sumIds.push(id)
			}
		},
		setPrevActive([sectionIndex, elementIndex]) {
			const prev = this.def.sections[sectionIndex].elements[elementIndex-1]
			if (prev) this.activeId = prev.id
		},
		setNextActive([sectionIndex, elementIndex], create) {
			const next = this.def.sections[sectionIndex].elements[elementIndex+1]
			if (next) this.activeId = next.id
			else if (create) this.addElement(sectionIndex)
		},
		getId() {
			return `$${nanoid()}`
		},
		getIndex(sectionIndex, elementIndex) {
			let count = 1
			for (let i=0; i<sectionIndex; i++) {
				count += this.def.sections[i].elements.filter(e => !['textBlock','formula'].includes(e.type)).length
			}
			return count + this.def.sections[sectionIndex].elements.slice(0,elementIndex).filter(e => !['textBlock','formula'].includes(e.type)).length
		},
		getElementDef(type=this.defaultType, id=this.getId()) {
			if (type!=='formula'&&type!=='textBlock') this.defaultType=type
			switch(type) {
				case 'shortComment':
					return { type, id, desc: undefined, required: undefined }
				case 'longComment':
					return { type, id, desc: undefined, required: undefined }					
				case 'checkbox':
					return { type, id, desc: undefined, valSelected: 1, valUnselected: 0 }
				case 'multipleChoice':
					return { type, id, choices: [{ desc: '', val: 0 }, { desc: '', val: 1 }, { desc: '', val: 2 }], desc: undefined, required: true }
				case 'number':
					return { type, id, min: 0, max: 500, desc: undefined, required: true }
				case 'numberSlide':
					return { type, id, min: 0, max: 10, desc: undefined, labelMin: undefined, labelMax: undefined, required: true }
				case 'textBlock':
					return { type, id, desc: undefined }	
				case 'formula':
					return { type, id, expr: '', label: undefined, outcome: undefined }																		
			}
		},
		addSection() {
			const def = { elements: [] }
			this.def.sections.push(def)
			setTimeout(() => setTimeout(() => {
				if (this.$refs.sections[this.def.sections.length - 1]) this.$refs.sections[this.def.sections.length - 1].scrollIntoView({ block: 'end',  behavior: 'smooth' })
			}, 0), 0)			
			return this.def.sections.length - 1
		},		
		delSection(sectionIndex) {
			this.$delete(this.def.sections, sectionIndex)
		},
		addElement(section,type) {
			// if (section === undefined) section = this.addSection()
			const def = this.getElementDef(type)
			this.def.sections[section].elements.push(def)
			//if (section===this.def.sections.length-1) this.addSection()
			this.activeId = def.id
		},
		copyQuestion([sectionIndex, elementIndex]) {
			const cln = cloneDeep(this.def.sections[sectionIndex].elements[elementIndex])
			cln.id = this.getId()
			this.def.sections[sectionIndex].elements.splice(elementIndex+1, 0, cln)
			setTimeout(()=>this.activeId = cln.id, 0)
		},
		delQuestion([sectionIndex, elementIndex]) {
			const elements = this.def.sections[sectionIndex].elements
			if (elementIndex<elements.length-1) setTimeout(() => this.activeId = elements[elementIndex].id, 0)
			else if (elementIndex) setTimeout(() => this.activeId = elements[elementIndex-1].id, 0)
			this.$delete(elements, elementIndex)
			if (!elements.length && this.def.sections.length>1) this.delSection(sectionIndex)			
		},
		changeType(type, [sectionIndex, elementIndex]) {
			const oldEl = this.def.sections[sectionIndex].elements[elementIndex]
			const el = this.getElementDef(type, oldEl.id)
			el.desc = oldEl.desc
			this.def.sections[sectionIndex].elements.splice(elementIndex, 1, el)
		},
		validate() {
			let success = true
			if (this.$refs.elements && this.$refs.elements.length) {
				this.$refs.elements.forEach(e=>{	
					if (typeof e.validate === 'function' && !e.validate()) success = false
				}) 
				if (!success) this.showSmallModal(Alert, { title: 'Oops, something isn\'t right!', message: 'Correct the errors before continuing.' })
			} else {
				success = false
				if (!success) this.showSmallModal(Alert, { title: 'Oops, something isn\'t right!', message: 'At least one question is required.' })
			}
			return success
		}
	},
	watch: {
		activeId(v) { 
			this.sumIds = [] 
			if (v) {
				this.$nextTick(()=>{
					if (!this.$refs.elements) return
					const el = this.$refs.elements.find(e=>e.def.id===this.activeId)	
					const o = new IntersectionObserver(entries => {
						if (!entries[0].isIntersecting) el.$el.scrollIntoView({ block: 'center' })
						o.disconnect()
					}, {
						root: this.$el.closest('.scrollContainer'),
						rootMargin: '0px',
						threshold: 1.0					
					}) 
					if (el) o.observe(el.$el)
				})
			}
		}
	}
}
</script>

<style lang='scss'>
.customOutcomeEditor {
	@include fill;
	position: relative;
	padding: 0 $size-gutter * 2;

	&.dragging .ProseMirror { pointer-events: none; }

	.outcomeHead {
		position: relative;
		margin-bottom: $size-gutter * 2;
		border: 1px solid $color-neutral-shadow;
		background: $color-neutral-shadow;
		display: grid;
		grid-template-columns: 1fr auto;

		.title {
		}

		.autoNumber {
			display: flex;
			align-items: center;
			background: #fff;
			padding: 0 $size-gutter * 2;
			> span { margin-right: $size-gutter; }
		}

		.instructions {
			min-width: 0;
			grid-column: 1 / -1;
		}
	}


	.sections {

		.section {
			margin-top: $size-gutter * 2;
			background: #fff;
			padding: $size-gutter * 3;
			border: 1px solid $color-neutral-shadow;

			header { 
				display: flex; 
				align-items: center;
				min-height: $size-control-height;
				margin-bottom: $size-gutter * 3;
				.title { color: $color-primary-accent; font-size: $size-font-large; text-decoration: underline; margin-right: $size-gutter * 4; margin-left: $size-gutter * 2; white-space: nowrap; }
				.desc { flex: 1; }
			}

			.dragging .richText { pointer-events: none; }

			.richText .ProseMirror {
				h1, h2, p, ul, ol, li { 
					&:not(:only-child) {
						margin-top: 0 !important; margin-bottom: 0 !important; 
					}
				}
			}

			&:not(.empty) { 
				.sectionHead .closeButton { display: none; }
				/* .richText { display: none; } */
			}			

		}

	}

	.elements > div > div:first-child .element.active { margin-top: 0 !important; }

	.elements > div:not(:empty) { margin-bottom: $size-gutter * 4; }

	.addSection { height: $size-control-height+2px; border: 1px solid $color-neutral-shadow; margin-top: $size-gutter * 2; }

	.ghost {
		opacity: 0 !important;
	}	

}
</style>