<script>
import 'chartjs-plugin-datalabels'
import { Line } from 'vue-chartjs'
import { dateTime } from '@/components/common/mixins/dateTime'

export default {
	extends: Line,
	mixins: [dateTime],
	props: ['results', 'grid', 'type'],
	data() { 
		const self = this
		return {
			options: {
				layout: {
					padding: {
						top: this.grid ? 14 : 28,
						bottom: this.grid ? 0 : 28,
						right: this.grid ? 28 : 28,
						left: this.grid ? 21 : 28,
					}
				},
				scales: {
					xAxes: [{
						type: 'time',
						time: {
							unit: 'day'
						},
						ticks: {
							padding: this.grid ? 10 : 0,
							display: this.grid,
							fontFamily: 'Cabin'
						},
						gridLines: {
							display: this.grid,
							drawBorder: false,
							drawTicks: this.grid
						}
					}],
					yAxes: [{
						ticks: {
							suggestedMin: this.getMin(),
							suggestedMax: this.getMax(),
							fontFamily: 'Cabin',
							padding: this.grid ? 21 : 0,
							display: this.grid
						},
						gridLines: {
							display: this.grid,
							drawTicks: this.grid,
							drawBorder: false
						}
					}],
				},
				legend: {
					display: false
				},
				tooltips: {
					enabled: this.grid && this.results.length >= 25,
					mode: 'nearest',
					intersect: false,
					//axis: 'x',
					titleFontFamily: 'Cabin',
					titleFontSize: '12',
					bodyFontFamily: 'Cabin',
					displayColors: false,
					titleMarginBottom: 0,
					xPadding: 16,
					yPadding: 12,
					caretPadding: 7,
					callbacks: {
						title: (tooltipItem) => {
							return `${this.date(tooltipItem[0].label)} - ${tooltipItem[0].value}`
						},
						label: () => false
					}
				},
				responsive: true,
				maintainAspectRatio: false,
				onClick: function() {
					if (!this.tooltip || !this.tooltip._lastActive[0]) return
					const index = this.tooltip._lastActive[0]._index
					self.$emit('click', index)
					if (self.grid) {
						this.options.plugins.datalabels.backgroundColor = this.options.plugins.datalabels.backgroundColor.map((v, i) => {
							if (i === index) return '#4d8fcf'
							else return '#d82424'
						})
						this.data.datasets[0].pointBackgroundColor = this.data.datasets[0].pointBackgroundColor.map((v, i) => {
							if (i === index) return '#4d8fcf'
							else return '#d82424'							
						})
						this.data.datasets[0].pointBorderColor = this.data.datasets[0].pointBorderColor.map((v, i) => {
							if (i === index) return '#4d8fcf'
							else return '#d82424'							
						})
						this.update()
					}
				},
//				hover: {
//					onHover: (event, chartElement) => event.target.style.cursor = chartElement[0] ? 'pointer' : 'default'	
//				},
				plugins: {
					datalabels: {
						//formatter: value => Math.round(value.y),
						formatter: value => value.y,
						display: context => {
							if (this.grid) return this.results.length < 25 
							else return context.dataIndex === 0 || context.dataIndex === this.results.length - 1
						},
						listeners: {
							enter: () => {
								self.$el.style.cursor = 'pointer'	
							},
							leave: () => {
								self.$el.style.cursor = 'default'	
							}
						},
						backgroundColor: [...Array(this.results.length).keys()].map(() => '#d82424'),
						borderRadius: 2,
						padding: { top: 6, right: 8, bottom: 4, left: 8 },
						color: '#ffffff',
						anchor: 'center',
						align: 'center',
						font: {
							family: 'Cabin',
							size: 12,
							weight: 'bold'
						}
					}
				}
			}
		}
	},
	methods: {
		parseData() {
			return this.results.map(point => ({
				t: point.created,
				y: point.value, //this.type==='grid' ? point.value : Math.round(point.value),
				id: point.id
			}))
		},
		getMin() {
			return 0
		},
		getMax() {
			if (this.type === 'VAS') return 100
			else if (this.type === 'NRS') return 10
			else if (this.type === 'NDI') return 100
			else if (this.type === 'RMDQ') return 24
			else if (this.type === 'LEFS') return 80
			else if (this.type === 'UEFI') return 80
			else if (this.type === 'QuickDASH') return 100
		}
	},
	mounted () {
		const data = {
			datasets: [{
				borderColor: '#d82424',
				borderWidth: 3,
				fill: this.grid,
				/*
				pointRadius: this.grid ? 
					this.results.length < 20 ? 12 : 3 : 
					this.results.length <= 2 ? [12,12] : [12].concat([...Array(this.results.length - 2).keys()].map(() => 0)).concat([12]),
				pointHoverRadius: this.grid ? 
					this.results.length < 20 ? 12 : 3 : 
					12,
				*/
				pointRadius: this.grid ? 5 : 0,
				pointHoverRadius: this.grid ? 5 : 0,
				pointBackgroundColor: [...Array(this.results.length).keys()].map(() => '#d82424'),
				pointBorderColor: [...Array(this.results.length).keys()].map(() => '#d82424'),
				pointBorderWidth: 0,
				pointHoverBorderWidth: 0,
				lineTension: 0.2,
				data: this.parseData()
			}]
		}
		this.renderChart(data, this.options)
	}
}
</script>

<style></style>