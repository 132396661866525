<template>
<SmallModal class='backdateManualOutcome' @close='$emit("close")' :showCancel='false'>
	<template v-slot:title>Date Questionnaire</template>
	<template v-slot:message>
		<p>By default, the results of this Questionnaire will be recorded with today's date.</p>
		<p>You can optionally backdate to any day in the past month if entering a previously completed Questionnaire.</p>
		<div class='dateWrapper'>
			<DatePicker 
				nav-visibility='hidden'
				:is-inline='true' 
				:columns='2' 
				:rows='1' 
				:min-date='minDate'
				:max-date='maxDate'
				:disabled-dates='disabled'
				is-expanded
				v-model='backdate' 
			/>
		</div>
		<p v-if='!backdate' class='warning'>Warning:  Date unavailable.  Please choose a date.</p>
	</template>
	<template v-slot:buttons>
		<div class='textButton primary' @click='onDone' :class='{ disabled: !backdate }'>
			<span class='label'>Continue</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import dayjs from 'dayjs'
import SmallModal from '@/components/common/modals/SmallModal'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
	name: 'OutcomeExists',
	components: {SmallModal, DatePicker},
	props: ['fn', 'disabled'],
	data: () => ({ 
		backdate: new Date(),
		minDate: new Date().setDate(new Date().getDate() - 30),
		maxDate: new Date()
	}),
	computed: {},
	methods: {
		onDone() {
			this.fn(this.backdate)
			this.$emit('close')
		}
	}
}
</script>

<style lang='scss'>
.backdateManualOutcome {
	.formContent { overflow: auto; }

	.dateWrapper { margin: $size-gutter * 2 0; > div { border: none; }}

	.warning { color: $color-alert; }

/*
	.vc-title, .vc-grid-cell {
		font-family: 'Cabin', sans-serif;
	}

	.vc-title {
		font-size: $size-font-large;
	}

	.vc-weekday { font-size: $size-font-standard; }

	.vc-day-content {
		font-size: $size-font-standard;
		margin: 0;
	}
*/
}
</style>