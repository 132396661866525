<template>
<div class='number'>
	<div v-if='index' class='no'><div>{{index}}.</div></div>
	<div class='question'>
		<div class='desc' v-html='def.desc' />
		<input type='number' :value='value' @input='onUpdate' @blur='onBlur' placeholder='Type here...' step='1' />
	</div>	
</div>
</template>

<script>
export default {
	name: 'Number',
	props: ['value','def','index'],
	methods: {
		onUpdate(e) { 
			this.$emit('input', e.target.value)
		},
		onBlur(e) { 
			let v = +e.target.value
			if (v < this.def.min) v=this.def.min
			else if (v>this.def.max) v=this.def.max 
			this.$emit('input', Math.round(v * 100) / 100)
		}		
	}
}
</script>

<style lang='scss'>
.customOutcomeRender .number {
	input {
		height: $size-control-height + 2;
		border: 1px solid $color-neutral-shadow;
		width: 160px;
		padding: 0 $size-gutter * 2;
		margin-top: $size-gutter * 2;
		background: #fff;
	}
	&.required input { border-color: $color-alert; }
}
</style>