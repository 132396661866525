<template>
<ElementHOC 
	:def='def' 
	:pos='pos' 
	:autoNumber='autoNumber' 
	:active='active'
	class='textBlock'
	ref='hoc'				
	placeholder='Description...' 
	v-on='$listeners'
>
</ElementHOC>
</template>

<script>
import ElementHOC from './HOC'

export default {
	name: 'TextBlock',
	components: { ElementHOC },
	props: ['def','pos','autoNumber','active'],
	methods: {
		validate() {
			return this.$refs.hoc.validate()
		}
	}
}
</script>

<style lang='scss'>
.customOutcomeEditor .textBlock:not(.active) {
	background: $color-neutral-silver;
	margin-top: $size-gutter * 2;
	margin-bottom: $size-gutter * 2;
	.desc .ProseMirror { 
		background: none !important;
		padding-top: $size-gutter * 3;
		padding-bottom: $size-gutter * 3;
	}
}
</style>