<template>
<div class='clientOutcomes'><div>
	<LatestOutcomes v-if='$store.state.manageClientOutcomes.latest.length' />
	<div v-if='!client.archived' class='createOutcomeButton iconTextButton neutral tagPrimary' @click='createOutcome'>
		<svg class='smallIcon'>
			<use xlink:href='#plus' />
		</svg>
		<span class='label'>{{$t("elements.buttons.outcome")}}</span>
	</div>
	<div class='resultInfoWrapper'>
		<ResultInfo :total='filteredKeys.length' :foundPhrase='$t("elements.results.outcomesFound")' />
		<div class='resultsFilter'>
			<div @click='active=!active'>
				<CheckButton :checked='active' />
				<span class='label'>Active</span>
			</div>
			<div @click='inactive=!inactive'>
				<CheckButton :checked='inactive' />
				<span class='label'>Inactive</span>
			</div>
		</div>			
	</div>
	<ClientOutcomesHeader v-if='filteredKeys.length' module='manageClientOutcomes' />
	<div class='clientOutcomesWrapper'>
		<template v-for='itemKey in filteredKeys'>
			<ClientOutcomeRow :itemKey='itemKey' :key='itemKey' />
		</template>
	</div>	
</div></div>
</template>

<script>
import Cookies from 'js-cookie'	
import { uniq } from 'lodash'
import { largeModalMixin } from '@/components/common/mixins/modal'
import ResultInfo from '@/components/common/ResultInfo'
import CheckButton from '@/components/common/buttons/Check'
//import Client from '@/components/clinician/clients/modals/Client'
import CreateOutcome from '@/components/clinician/AssignOutcomeToClient'
import ClientOutcomesHeader from './ClientOutcomesHeader'
import ClientOutcomeRow from './ClientOutcomeRow'
import LatestOutcomes from './LatestOutcomes'

export default {
	name: 'ClientOutcomes',
	mixins: [largeModalMixin],
	components: {
		ResultInfo, CheckButton,
		ClientOutcomesHeader,
		ClientOutcomeRow,
		LatestOutcomes	
	},
	props: ['client'],
	data: () => ({ 
		active: true,
		inactive: true
	}),
	computed: {
		loading() { return this.$store.state.manageClientOutcomes.apiPending || this.$store.state.sets.apiPending },
		clientOutcomeKeys() { return this.$store.state.manageClientOutcomes.list },
		activeKeys() {
			return this.clientOutcomeKeys.filter(k=>{
				const o = this.$store.state.manageClientOutcomes.outcomes[k]
				const d1 = new Date(o.lastSentOn)
				const c1 = new Date(o.created)
				const d2 = new Date()
				const sixWeeks = 1000 * 60 * 60 * 24 * 7 * 6
				return (
					d2.getTime()-d1.getTime() < sixWeeks || 
					d2.getTime()-c1.getTime() < sixWeeks || 
					o.enabled 
				)
			})
		},
		inactiveKeys() {
			return this.clientOutcomeKeys.filter(k=>{
				const o = this.$store.state.manageClientOutcomes.outcomes[k]
				const d1 = new Date(o.lastSentOn)
				const d2 = new Date()
				return (
					d2.getTime()-d1.getTime() >= 1000 * 60 * 60 * 24 * 7 * 6 &&
					!o.enabled
				)
			})
		},	
		filteredKeys() {
			if (this.active && !this.inactive) {
				return this.activeKeys
			} else if (!this.active && this.inactive) {
				return this.inactiveKeys
			} else if (this.active && this.inactive) {
				return uniq([...this.activeKeys, ...this.inactiveKeys])
			} else {
				return []
			}
		},
		//totalOutcomes() { return this.$store.state.manageClientOutcomes.meta.total }
	},
	methods: {
		fetchTop() { this.$store.dispatch('manageClientOutcomes/fetchTop') },
		fetchNext() { this.$store.dispatch('manageClientOutcomes/fetchNext') },
		createOutcome() { 
			const cb = ({ client }) => {
				this.$store.dispatch('clients/incOutcomeCount', client.id)
				this.$store.dispatch('manageClientOutcomes/fetchTop')				
			}
			const fn = () => this.showLargeModal(CreateOutcome, { client: this.client, cb, sticky: true }) 
			fn()
			/*
			if (this.client.email && this.client.portalKey) fn()
			else this.showSmallModal(Client, {
				client: this.client, 
				emailRequired: true,
				onSave: fn
			})	
			*/
		}
	},
	watch: {
		client(newClient, oldClient) {
			if (newClient.key && newClient.key !== oldClient.key) 
				this.$store.dispatch('manageClientOutcomes/setClient', newClient.key)
		},
		loading(v) {
			this.$emit('working', v)
		},
		active(v) {
			Cookies.set(`${this.$store.state.profile.user.id}-clientOutcomesActive`, v)
			if (!v && !this.inactive) this.inactive=true
		},
		inactive(v) {
			Cookies.set(`${this.$store.state.profile.user.id}-clientOutcomesInactive`, v)	
			if (!v && !this.active) this.active=true					
		}
	},
	mounted() {
		if (this.client && this.client.key !== this.$store.state.manageClientOutcomes.client) 
			this.$store.dispatch('manageClientOutcomes/setClient', this.client.key)
		const active = Cookies.get(`${this.$store.state.profile.user.id}-clientOutcomesActive`)
		const inactive = Cookies.get(`${this.$store.state.profile.user.id}-clientOutcomesInactive`)
		if (active === 'true') this.active = true 
		else if (active === 'false') this.active = false
		if (inactive === 'true') this.inactive = true 
		else if (inactive === 'false') this.inactive = false
		this.$store.commit('notifications/markRead', { clientId: this.client.id, type: 'clientOutcomeCompleted' })
	}
}
</script>

<style lang='scss'>
.clientsManager .clientOutcomes {

	position: relative;

	.createOutcomeButton { 
		margin: $size-gutter * 2; 
		box-sizing: content-box;
		border: 1px solid transparent;
		background: none;
	}

	.clientOutcomesWrapper {
		> div:last-child {
			border-bottom: 1px solid $color-neutral-shadow;
		}
	}

	.resultInfoWrapper {
		margin-top: $size-gutter * 2 + 1;		
		display: flex;
		align-items: bottom;
	}

	.resultsFilter {
		margin-left: auto;
		display: flex;
		padding-right: $size-gutter * 4;
		padding-top: 11px;
		position: relative;

		> div {
			display: flex;
			align-items: center;
			font-size: $size-font-standard;
			padding-left: $size-gutter * 2;
			cursor: pointer;
			.checkButton { margin-right: $size-gutter; pointer-events: none; }
		}
	}	

	.loading { background: rgba($color-neutral-panel, 0.3); }

}
</style>
