<template>
<div class='outcomeHistory'>
	<Scroll v-if='rows.length'>
		<div class='content'>
			<template v-for='(row, index) in rows'>
				<div
					v-if='row.type === `email`'
					:key='index'
					class='email'
				>
					<span class='date'><b>{{dateTime(row.created)}}</b></span>
					<span>Email sent to&nbsp;<b>{{row.email}}</b></span>
					<span class='status' :class='[row.status]'>
						<template v-if="row.status==='delivery'">&nbsp;&nbsp;&nbsp;Email Delivered</template>
						<template v-if="row.status==='open'">&nbsp;&nbsp;&nbsp;Client Opened</template>
						<template v-if="row.status==='click'">&nbsp;&nbsp;&nbsp;Client Clicked</template>
						<template v-else-if="row.status==='bounce'">&nbsp;&nbsp;&nbsp;Email Bounced</template>
						<template v-else-if="row.status==='complaint'">&nbsp;&nbsp;&nbsp;Client Marked as Spam</template>
					</span>
				</div>
				<div
					v-else-if='row.type === `result`'
					:key='index'
					:class='{ selected: selected===row.id }'
					@click='() => selectResult(row.id)'
					class='result'
				>
					<span class='date'><b>{{dateTime(row.created)}}</b></span><span class='name'>{{client.firstname}} {{client.lastname}} completed questionnaire</span>
					<div class='scoreWrapper'>
						<div v-for='(score, i) in formulaValues(row.id)' :key='i' class='miniButton success'>{{score.label}}: {{score.value}}</div>
					</div>
				</div>
			</template>
		</div>
	</Scroll>
	<div v-else-if='!loading' class='noData'>No data</div>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { clientOutcomesApi } from '@/services/api/modules/clinician/clientOutcomes'
import { dateTime } from '@/components/common/mixins/dateTime'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'

export default {
	name: 'OutcomeHistory',
	mixins: [dateTime],
	components: { Loading, Scroll },
	props: ['outcomeId', 'client', 'type', 'formulaResults'],
	data: () => ({
		loading: false,
		emails: [],
		results: [],
		selected: null
	}),
	computed: {
		rows() {
			return (
				this.results
				.concat(this.emails)
				.sort((a,b) => new Date(b.created) - new Date(a.created))
			)
		}
	},
	methods: {
		async fetch() {
			this.loading = true
			const [err, result] = await clientOutcomesApi.touch('fetchHistory', { id: this.outcomeId })
			if (!err) {
				const { emails, results } = result
				this.emails = emails.map(v => Object.assign({ type: 'email' }, v))
				this.results = results.map(v => Object.assign({ type: 'result' }, v))
			}
			this.loading = false
			const r = this.rows.find(r=>r.id) 
			if (r) this.selectResult(r.id)
		},
		selectResult(resultId) {
			this.selected = resultId			
			this.$emit('selectResult', resultId)
		},
		formulaValues(id) {
			if (!this.formulaResults) return []
			//if (this.type==='custom') {
				return this.formulaResults.map(a=>{
					const o = a.results.find(b=>b.id===id)
					return o ? { label: a.desc, value: o.value } : null
				}).filter(v=>v)
			//} else {
			//	const o = this.formulaResults.find(a=>a.id===id)
			//	return o ? [{ label: 'Score', value: o.value }] : null
			//}
		}
	},
	async mounted() {
		await this.fetch()
	}
}
</script>

<style lang='scss'>
.outcomeHistory {
	@include fill;
	display: flex;

	.noData { margin: auto; color: $color-neutral-shadow; font-size: 30px; }

	.content {
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		b {
			font-weight: bold;
			color: $color-primary-accent;
		}
		> div {
			min-height: $size-control-height;
			display: flex;
			align-items: center;
			padding: $size-gutter $size-gutter * 4;
			background: $color-white;
		}
		.date { flex: 0; flex-basis: 130px; }
		.name { flex: 1.5;  }
		.email {
			background: $color-neutral-silver;
			span:last-child { flex: 3; }
		}
	.status {
				&.send { color: $color-primary-accent; }
				&.delivery, &.open, &.click  { color: $color-success; }
				&.bounce, &.complaint { color: $color-alert;  }
	}
		.result {
			cursor: pointer;
			&.selected { background: $color-focus; }	
		}
		.scoreWrapper {
			flex: 2; 
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding-left: $size-gutter * 2;
			.miniButton { margin-left: 1px; margin-bottom: 1px; position: relative; top: 1px; }
		}
	}
}
</style>