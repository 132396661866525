<template>
<div 
	class='clientOutcomeRow' 
	:class='{ disabled: !outcome.enabled, newOutcome }' 
	@click.stop='openOutcome'
>
	<div class='titleCol'>
		<svg v-if='locked' class='lockIcon smallIcon'>
			<use xlink:href='#lock' />
		</svg>
		<div v-if='outcome.type==="custom"'>{{outcome.customTitle || 'Untitled'}}</div>
		<div v-else>{{$t(`elements.outcomes.${outcome.type}`)}}</div>
		<div v-if='newOutcome' class='newOutcome' />
	</div>
	<div class='lastSentCol'>
		<div class='lastSent'>
			<div>{{lastSent || '--'}}</div>
			<div class='status' :class='[outcome.status]'>
				<template v-if="outcome.status==='delivery'">Delivered</template>
				<template v-if="outcome.status==='open'">Opened</template>
				<template v-if="outcome.status==='click'">Clicked</template>
				<template v-else-if="outcome.status==='bounce'">Bounced</template>
				<template v-else-if="outcome.status==='complaint'">Marked as Spam</template>
			</div>
		</div>
	</div>
	<div class='lastCompletedCol'>
		<div class='lastCompleted'>{{lastCompleted || '--'}}</div>
	</div>
	<div class='nextEmailCol'>
		<DraggableSwitch
			v-if='canEnable'
			:value='outcome.enabled'
			:clickable='true'
			@input='setEnabled'
			@click.native.stop
		/>
		<div class='nextEmail'>
			<template v-if='outcome.enabled'>{{nextEmail || '--'}}</template>
			<template v-if='canEnable && !outcome.enabled'>
				<span v-if='outcome.clientStopped' class='clientStopped'>{{$t('elements.labels.clientStopped')}}</span>
				<span v-else class='paused'>{{$t('elements.labels.paused')}}</span>
			</template>
		</div>
		<CloseButton v-if='!outcome.totalCompleted' @click.stop='deleteOutcome' />
	</div>

</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import { largeModalMixin, smallModalMixin } from '@/components/common/mixins/modal'
import DraggableSwitch from '@/components/common/DraggableSwitch'
import CloseButton from '@/components/common/buttons/Close'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
//import EditOutcome from '@/components/clinician/outcomeAssigner/EditOutcome'
import EditOutcome from '@/components/clinician/InspectClientOutcome'


export default {
	name: 'ManageClientOutcomeRow',
	mixins: [dateTime, largeModalMixin, smallModalMixin],
	components: { DraggableSwitch, CloseButton },
	props: ['itemKey'],
	computed: {
		outcome() { 
			return this.$store.state.manageClientOutcomes.outcomes[this.itemKey] || {} 
		},
		expiresOn() { 
			this.langBackdoor
			return this.date(this.outcome.expiresOn, false) 
		},
		lastSent() { 
			this.langBackdoor
			return this.outcome.lastSentOn ? this.date(this.outcome.lastSentOn) : false
		},		
		lastCompleted() { 
			this.langBackdoor
			return this.outcome.lastCompletedOn ? this.date(this.outcome.lastCompletedOn) : false
		},
		nextEmail() { 
			this.langBackdoor
			return this.outcome.nextEmailOn ? this.date(this.outcome.nextEmailOn.split('T')[0], false) : false
		},
		locked() { 
			return this.$store.state.manageClientOutcomes.client ? 
				this.$store.state.clients[this.$store.state.manageClientOutcomes.client].archived : 
				null 
		},
		canEnable() {
			return (
				!this.locked && 
				new Date(this.outcome.expiresOn) > new Date() &&
				this.nextEmail
			)
		},
		newOutcome() {
			const notifications = Object.values(this.$store.state.notifications.notifications)
			return !!notifications.find(n=>n.outcomeId===this.outcome.id && n.type==='clientOutcomeCompleted' && n.viewed===false)
		}
	},
	methods: {
		setEnabled(value) {
			this.$store.dispatch('manageClientOutcomes/setEnabled', { key: this.outcome.key, value })
		},
		deleteOutcome() {
			const fn = () => this.$store.dispatch('manageClientOutcomes/destroy', this.outcome.key)
			this.showSmallModal(ConfirmDeleteModal, { items: this.$t(`elements.outcomes.${this.outcome.type}`), fn })
		},
		openOutcome() {
			this.showLargeModal(EditOutcome, { id: this.itemKey, sticky: true }, '100%') 
		}
	}
}
</script>

<style lang='scss'>
.clientOutcomeRow {
	overflow: hidden;
	position: relative;
	display: flex;
	font-size: $size-font-standard;
	background: $color-white;
	margin-left: $size-gutter * 2;
	margin-right: $size-gutter * 2;
	box-sizing: content-box;
	border-left: 1px solid $color-neutral-shadow;
	border-right: 1px solid $color-neutral-shadow;
	cursor: pointer;

	&:hover { background: $color-neutral-silver;  }

	&.newOutcome {
		color: $color-primary-accent;
	}

	> div {
		overflow: hidden;
		display: flex;
		align-items: center;
		min-height: $size-control-height;
		padding: 0 $size-gutter * 2;
		&:not(:first-child) { border-left: 1px solid $color-neutral-shadow; }
	}

	.titleCol {
		flex: 4;
		min-width: 0;
	}

	.lockIcon {
		margin-right: $size-gutter * 2;
		color: $color-neutral-shadow;
	}

	.lastSentCol {
		flex: 1.5;
		.status {
				&.send { color: $color-primary-accent; }
				&.delivery, &.open, &.click  { color: $color-success; }
				&.bounce, &.complaint { color: $color-alert;  }
		}
	}

	.lastCompletedCol { flex: 1.5 }

	.nextEmailCol { 
		flex: 2.75;
		.paused { display: block; font-size: $size-font-small; font-weight: bold; color: $color-primary-accent; }
		.clientStopped { display: block; font-size: $size-font-small; font-weight: bold; color: $color-alert; }
		.closeButton { margin-left: auto; padding-right: 0; }
	}

	.lastSentCol, .lastCompletedCol, .nextEmailCol { font-size: $size-font-small; line-height: $size-lineHeight-small; }

	.draggableSwitch { z-index: 10; margin-right: $size-gutter * 2; }
	.trackFalse { background: $color-neutral-shadow; }
	.trackTrue { background: $color-success; }
	.handle { background: $color-neutral-accent; }
	.views {
		font-size: $size-font-small;
		margin-left: $size-gutter;
		color: $color-neutral-shadow;
		margin-right: auto;

		&.enabled { color: $color-black; }
	}

	.newOutcome { width: 12px; height: 12px; border-radius: 50%; background: $button-gradient-alert; margin-left: $size-gutter; min-width: 12px; }
}


</style>