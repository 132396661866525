<template>
<div class='outcomesList'>

	<div class='controls'><div>
		<SearchInput 
			v-model='localSearch'
			@input='fetchSearch'
			@submit='fetchSearchImmediate'
			:placeholder='$t("views.outcomesManager.phSearchOutcomes")'
		/></div>
	</div>

	<div class='content'>
		<RecycleScroller
			:items='compositeItems'
			:item-size='40'
			:buffer='500'
			class='recycleScroller'
		>
			<template slot='before'>
				<div class='pathWrapper'>
					<div v-if="folderId" class='fullPath bubble'>
						<div class="content fullPath">
							<span 
								v-for='(node, index) in pathNodes(folderId)' 
								:key='index'
								@click='$store.dispatch("manageOutcomes/setFolderId", node.id)'
							>/&nbsp;&nbsp;<span>{{node.title}}</span>&nbsp;&nbsp;
							</span>	
							<!--<span v-html="pathTo(folderId, true)" />-->
						</div>
						<CloseButton @click='$store.dispatch("manageOutcomes/setFolderId", null)' />
					</div>				
					<div v-else class='fullPath bubble'>
						<div class="content">
							<span>Results from all folders...</span>
						</div>
					</div>	
					<ResultInfo :total='compositeTotal' :searched="searched" />	
				</div>
				<div class='subfolders'>
					<div 
						v-for='(folder, index) in subfolders' 
						:key='index'
						@click='$store.dispatch("manageOutcomes/setFolderId", folder.id)'
						class='folder'
					>
						<svg class='smallIcon'>
							<use xlink:href='#folders' />
						</svg>		
						<span class='label'>{{folder.title}}</span>
					</div>
				</div>
			</template>		
			<template slot='sticky'>
				<TableColumns v-if='compositeTotal'
					module='manageOutcomes'
					:columns='[{
						label: $t("elements.cols.outcome"),
						selectable: true,
						sort: "savename",
						flex: 1
					}]'
				/>
			</template>
			<template v-slot='{ item }'>
				<ListItem 
					:item='item' 
					:itemKey='item.id' 
					:path='pathTo(item.folderId)' 
					:canEdit='canEdit(item.folderId)' 
					:selected='selected'
					@select='v=>$emit("select", v)'
					@showContextMenu='showContextMenu'
				/>
			</template>
			<template slot='after'>
				<div class='buildYourOwn'>
					<a class='tag success' href='https://www.youtube.com/watch?v=YPy_3vzdAg0&list=PLrtgSW-phj2qPK6YAzfy1c-ud0-Icm85e' target='_blank'>Learn how to build your own!</a>
				</div>				
				<AtBottom :action='fetchNext' />
			</template>
		</RecycleScroller>
	</div>
	<transition name='fade'><Loading v-show='working' /></transition>		
	<ContextMenu 
		v-if='contextMenu' 
		:e='contextMenu'
		@close='contextMenu=false'
	>
		<div 
			class='iconTextButton neutral'
			:class='{ disabled: !canRenameSelected }'
			@click='$emit("rename", contextItem)'
		>
			<svg class='smallIcon'>
				<use xlink:href='#edit' />
			</svg>
			<span class='label'>Rename <template v-if='typeof canRenameSelected==="string"'>&quot;{{canRenameSelected}}&quot;</template></span>
		</div>				
	</ContextMenu>	

</div>
</template>

<script>
import { debounce, isEqual } from 'lodash'
import { outcomesApi } from '@/services/api/modules/clinician/outcomes'
import RecycleScroller from '@/libs/vueVirtualScroller/components/RecycleScroller'
import ResultInfo from '@/components/common/ResultInfo'
import TableColumns from '@/components/common/TableColumns'
import AtBottom from '@/components/common/AtBottom'
import SearchInput from '@/components/common/SearchInput'
import CloseButton from '@/components/common/buttons/Close'
import Loading from '@/components/common/Loading'
import ContextMenu from '@/components/common/ContextMenu'
import ListItem from './ListItem'

export default {
	name: 'OutcomesList',
	components: { Loading, SearchInput,CloseButton, RecycleScroller, TableColumns, AtBottom, ListItem, ResultInfo, ContextMenu },
	props: ['edit','folders','selected'],
	data() { return {
		localSearch: '',
		contextMenu: false,
		contextItem: null,
		working: false,
		total: 0,
		items: [],
		stockOutcomes: {
			VAS: this.$t('elements.outcomes.VAS'),
			NRS: this.$t('elements.outcomes.NRS'),
			RMDQ: this.$t('elements.outcomes.RMDQ'),
			QuickDASH: this.$t('elements.outcomes.QuickDASH'),
			UEFI: this.$t('elements.outcomes.UEFI'),
			LEFS: this.$t('elements.outcomes.LEFS'),
			SF36: 'Short Form 36 (SF36)',
			KOOS: 'Knee Injury and OA Outcome Score (KOOS)',
			DASS21: 'Depression Anxiety and Stress Scale 21 (DASS21)',
		},
		fetchSearch: debounce(function(search) {
			this.$store.dispatch('manageOutcomes/setSearch', search)
				.then(() => this.fetchTop())
		}.bind(this), 1000, { leading: false, trailing: true })
	}},
	computed: {
		folderId() { return this.$store.state.manageOutcomes.folderId },
		search() { return this.$store.state.manageOutcomes.search },
		searched() { return this.$store.state.manageOutcomes.searched },
		sort() { 
			return {
				col: this.$store.state.manageOutcomes.sortColumn,
				dir: this.$store.state.manageOutcomes.sortDirection
			}
		},
		filter() {
			return {
				folderId: this.folderId,
				search: this.search,
				sort: this.sort.col,
				sortDir: this.sort.dir,
			}
		},
		stockItems() {
			const items = Object.keys(this.stockOutcomes)
				.filter(k => this.stockOutcomes[k].toLowerCase().includes(this.searched.toLowerCase()))
				.map(k => ({ id: k, savename: this.stockOutcomes[k] }))
			return items
		},
		compositeItems() {
			const compositeItems = this.folderId === 'SimpleSet' ? 
				this.stockItems : this.folderId ? 
				this.items : 
				[ ...this.items, ...this.stockItems ]
			if (this.sort.col==='savename') {
				compositeItems.sort((a,b) => a.savename.localeCompare(b.savename))
				if (this.sort.dir==='desc') compositeItems.reverse()
			}
			return compositeItems
		},
		compositeTotal() {
			return this.compositeItems.length
		},
		canRenameSelected() {
			const item = this.items.find(i=>i.id===this.contextItem.id)
			if (!item || !this.canEdit(item.folderId)) return false 
			else return item.savename || true
		},
		subfolders() {
			if (this.$store.state.manageOutcomes.folderId) {
				return Object.values(this.folders).filter(f=>f && f.parentId === this.$store.state.manageOutcomes.folderId).sort((a,b)=>a.index-b.index)
			} else if (!this.$store.state.manageOutcomes.searched) {
				return [
					{ title: 'SimpleSet Outcomes', id: 'SimpleSet' },
					Object.values(this.folders).find(f=>f && !f.parentId && f.type==='user'),
					...Object.values(this.folders).filter(f=>f && !f.parentId && f.type==='team'),
					Object.values(this.folders).find(f=>f && !f.parentId && f.type==='org')
				].filter(v=>v)
			} else {
				return null
			}
		}
	},
	methods: {	
		showContextMenu(e, outcomeId) {
			this.contextMenu = e 
			this.contextItem = outcomeId
		},
		canEdit(folderId) {
			if (!this.edit) return false
			const type = this.folders[folderId] ? this.folders[folderId].type : null
			return (
				type === 'user' ||
				type === 'org' && this.$store.state.profile.user.orgOutcomes === 'readWrite' ||
				(type === 'team' && this.$store.state.profile.teams[this.folders[folderId].teamId].teamOutcomes === 'readWrite')
			) 		
		},		
		fetchSearchImmediate() {
			this.fetchSearch.flush()
		},
		fetchTop() {
			if (this.folderId === 'SimpleSet') {
				this.$store.dispatch('manageOutcomes/setSearched', this.filter.search)
			} else {
				this.working = true
				return outcomesApi.touch('fetchList', this.filter).then(([err, result]) => {
					if (!err) {
						this.items = result.items
						this.total = result.total
						this.$store.dispatch('manageOutcomes/setSearched', this.filter.search)
					}
				})
				.finally(() => this.working = false)
			}
		},
		fetchNext() {
			if (this.folderId === 'SimpleSet') return			
			if (this.items.length < this.total) {
				this.working = true
				return outcomesApi.touch('fetchList', { skip: this.items.length, ...this.filter }).then(([err, result]) => {
					if (!err) this.items.push(...result.items)
				})
				.finally(() => this.working = false)
			}
		},
		moveOutcome(outcomeId, folderId) {
			this.working = true
			outcomesApi.touch('move', { outcomeId, folderId })
				.then(([err]) => {
					if (!err) {
						this.$emit('moved', { outcomeId, folderId })
						return this.fetchTop()
					}
				})
				.finally(() => this.working = false)
		},
		pathNodes(folderId) {
			const nodes = []
			const fn = (node) => {
				nodes.unshift({ title: node.title, id: node.id })
				if (node.parentId) fn(this.folders[node.parentId])
			}
			if (this.folders[folderId]) fn(this.folders[folderId])
			if (!nodes.length) nodes.push({ id: 'SimpleSet', title: 'Outcomes' })
			return nodes
		},		
		pathTo(folderId, fullPath) {
			const nodes = []
			const fn = (node) => {
				nodes.unshift(node.title)
				if (
					node.parentId && 
					(fullPath || node.id !== this.$store.state.manageOutcomes.folderId)
				) fn(this.folders[node.parentId])
			}
			if (this.folders[folderId]) fn(this.folders[folderId])
			return "/ " + (nodes.join("&nbsp;/ ") || "SimpleSet")		
		}
	},
	watch: {
		folderId(a,b) { if (a!==b) this.fetchTop() },
		sort(a,b) { if (!isEqual(a, b)) this.fetchTop() },
	},
	mounted() {
		this.localSearch = this.search
		this.fetchTop()
	}
}
</script>

<style lang='scss'>
.outcomesList {
	@include fill;
	min-width: 300px;	
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;

	.buildYourOwn { margin-right: $size-gutter * 2; display: flex; justify-content: center; > a { text-decoration: none !important; margin-top: $size-gutter * 4; font-size: $size-font-standard;   }}

	.controls {
		margin: $size-gutter * 2;
		margin-bottom: 0;
		margin-left: 0;

		> div {
			border: 1px solid $color-neutral-shadow;
			background: $color-neutral-shadow;
		}
	}


	.content { 
		position: relative; 
		.pathWrapper { 
			display: flex; 
			align-items: center;
			> div:first-child { margin-right: auto; }
		}		
		.fullPath { 
			font-weight: bold; color: $color-primary-accent; background: none; border: none; 
			> span > span {
				cursor: pointer;
				&:hover { text-decoration: underline; }
			}
			.closeButton { margin-right: auto; margin-left: 0; }
		}		
		.tableColumns {
			margin: $size-gutter * 2;
			margin-left: 0;
			margin-bottom: 0;
		}
		.resultInfo { }
		.resultWrapper {
			display: flex;
			align-items: flex-end;
			margin-top: $size-gutter * 2;
			.resultInfo { margin: 0; padding-left: $size-gutter * 2; }
			> a { 
				font-weight: bold;
				margin-left: auto; 
				white-space: nowrap; 
				margin-right: $size-gutter * 4;
				font-size: $size-font-standard;
				color: $color-primary-accent;
			}
		}
	}

	.subfolders {
		flex-basis: 100%;
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
		min-height: $size-control-height;
		padding-bottom: $size-gutter;
		padding-top: $size-gutter * 4;
		border: 1px solid transparent;

		.folder {
			margin: 3.5px $size-gutter;
			padding: 0; 
			font-size: $size-font-small;
			align-items: center;
			display: flex;
			padding-left: $size-gutter;
			cursor: pointer;
			color: $color-primary-accent;
			font-weight: bold;
			svg { margin-right: $size-gutter * 2; color: $color-neutral-dark; }
			&:hover { 
				text-decoration: underline;
				svg { color: $color-primary-accent; }
			}
		}		
	}	

	.vue-recycle-scroller__item-wrapper:after {
		left: 0 !important;
		width: calc(100% - #{$size-gutter * 2}) !important;
	}		
}
</style>