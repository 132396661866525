<template>
<ElementHOC 
	:def='def' 
	:pos='pos' 
	:autoNumber='autoNumber' 
	:active='active'
	:errs='errs'
	:descMaxLength='50'
	ref='hoc'		
	placeholder='Description...' 
	class='formula'
	v-on='$listeners'
>
	<div class='expr'>
		<span class='equals'>＝</span>
		<TextInput 
			:value='def.expr'
			@input='v=>def.expr=v.replace(":","")'
			placeholder='Expression...' 
			maxlength='500' 
			class='value'				
		/>
		<div @click='getSumExpr()' class='blockButton'>
			<svg class='smallIcon'>
				<use xlink:href='#sigma' />
			</svg>
		</div>	
	</div>
</ElementHOC>
</template>

<script>
import { uniq } from 'lodash'
import { evaluate } from 'mathjs'
import { smallModalMixin } from '@/components/common/mixins/modal'
import TextInput from '@/components/common/TextInput'
import AlertModal from '@/components/common/modals/Alert'	
import ElementHOC from './HOC'

export default {
	name: 'Formula',
	mixins: [smallModalMixin],
	components: { ElementHOC, TextInput },
	props: ['def','pos','autoNumber','active','sumIds','root'],
	data() { return {
		errs: []
	}},		
	computed: {
		formulaEls() { 
			return this.root.sections
				.map(s=>s.elements)
				.flat()
				.filter(e=>{
					return (
						(e.type==='checkbox' && typeof e.valSelected==='number' && typeof e.valUnselected==='number') ||
						(e.type==='multipleChoice' && e.choices.every(c=>typeof c.val==='number')) || 
						e.type==='numberSlide' || e.type==='number'
					)
				})
		},
		/*
		showSum() { return uniq(this.formulaEls.map(e=>e.type)).length===1 }
		*/		
	},
	methods: {
		validate() {
			this.errs = []
			if (this.def.desc && this.def.desc.length > 50) this.errs.push('Description too long')			
			if (!this.def.expr.trim().length) {
				this.errs.push('Expression required')
			} else {
				try {
					const scope = {}
					this.formulaEls.forEach(e=>scope[e.id]=1) // good enough to prevent typos
					evaluate(this.def.expr, scope)
				} catch(err) {
					this.errs.push('Bad expression')
				}
			}
			const success = this.$refs.hoc.validate()
			return success && !this.errs.length
		},
		getSumExpr() {
			//this.def.expr=this.formulaEls.map(e=>e.id).join('+')
			if (this.sumIds.length) {
				this.def.expr=this.sumIds.join('+')
				this.sumIds.splice(0,this.sumIds.length)
			} else {
				this.showSmallModal(AlertModal, {
					title: 'Sum',
					message: 'Select questions to sum with CTRL + click.'
				})
			}
		}
	}
}
</script>

<style lang='scss'>
.customOutcomeEditor .formula {

	.expr {

		display: flex;
		align-items: center;

		.equals { 
			padding-left: $size-gutter * 2;
			font-size: $size-font-large;
			color: $color-primary-accent;
		}
		.value { flex: 1; }
	}

	&:not(.active) {
		background: $color-neutral-silver;
		margin-top: $size-gutter * 2;
		margin-bottom: $size-gutter * 2;
		.desc .ProseMirror { 
			background: none !important;
			padding-top: $size-gutter * 3;
			padding-bottom: $size-gutter * 3;
		}		
	}

}
</style>