<template>
<div class='customOutcomeRender' :class='{ preview }'>	

	<div class='section'>

		<div v-if='sectionIndex===0 && (def.title || def.instructions)' class='outcomeHead'>
			<div v-if='def.title' class='title'>{{def.title}}</div>
			<div v-if='def.instructions' class='instructions' v-html='def.instructions' />
		</div>		
		
		<div class='sectionHead'>
			<div v-if='sections[sectionIndex] && sections[sectionIndex].desc && sections[sectionIndex].desc.replace(/<[^>]+>/g, "").trim()' class='desc' v-html='def.sections[sectionIndex].desc' />
		</div>

		<div v-if='sections[sectionIndex]' class='elements'>
			<component 
				v-for='(element, i) in sections[sectionIndex].elements' 
				:key='element.id'
				:is='`${element.type[0].toUpperCase()}${element.type.substr(1)}`' 
				:def='element'
				:root='def'
				:result='result'
				:index='def.autoNumber ? getIndex(sectionIndex,i) : null'
				:class='{ required: required && element.required && !result[element.id] && result[element.id]!==0 }'
				v-model='result[element.id]'
				ref='elements'
			/>
		</div>

		<div class='controlBar'>
			<div v-if='sections.length > 1 && sectionIndex' class='backButton textButton neutral' @click='goto(sectionIndex-1)'>
				<span class='label'>Back</span>
			</div>
			<div v-if='sections.length > 1 && sectionIndex < sections.length - 1' class='nextButton textButton neutral' @click='goto(sectionIndex+1)'>
				<span class='label'>Next</span>
			</div>	
			<div v-else class='submitButton textButton neutral' @click='submit'>
				<span class='label'>Submit</span>
			</div>
		</div>

	</div>

</div>
</template>

<script>

//import { smallModalMixin } from '@/components/common/mixins/modal'

import ShortComment from './elements/ShortComment'
import LongComment from './elements/LongComment'
import Checkbox from './elements/Checkbox'
import MultipleChoice from './elements/MultipleChoice'
import Number from './elements/Number'
import NumberSlide from './elements/NumberSlide'
import TextBlock from './elements/TextBlock'
import Formula from './elements/Formula'

export default {
	name: 'CustomOutcomeRender',
	components: {
		ShortComment, LongComment, Checkbox, MultipleChoice, Number, NumberSlide, TextBlock, Formula
	},
	//mixins: [smallModalMixin],	
	props: {
		def: {
			type: Object,
			default: () => ({})
		},
		result: {
			type: Object,
			default: () => ({})			
		},
		preview: {
			type: Boolean,
			default: false
		}
	},
	data() { 
		return { sectionIndex: 0, required: false }
	},
	computed: {
		sections() {
			return this.def.sections.filter(s => s.elements && s.elements.length)
		}
	},
	/*
	computed: {
		formulaIds() {
			return uniq(this.def.sections.map(s=>s.elements.filter(e=>e.type==='formula').map(e=>([...e.expr.matchAll(/\$.../g)]))).flat(3))
		}
	},
	*/
	methods: {
		getIndex(sectionIndex, elementIndex) {
			let count = 1
			for (let i=0; i<sectionIndex; i++) {
				count += this.def.sections[i].elements.filter(e => !['textBlock','formula'].includes(e.type)).length
			}
			return count + this.def.sections[sectionIndex].elements.slice(0,elementIndex).filter(e => !['textBlock','formula'].includes(e.type)).length
		},
		goto(sectionIndex) {
			if (
				sectionIndex>this.sectionIndex && this.validate() ||
				sectionIndex<this.sectionIndex
			) {
				this.sectionIndex = sectionIndex
				this.$emit('scrollToTop')
			} else {
				this.$emit('err')
			}
		},
		submit() {
			if (this.validate()) {
				this.$emit('submit', this.result)
				//this.showSmallModal(Alert, { title: 'Looks great!', message: 'Your Outcome Meausure is working perfectly!' })
			} else {
				this.$emit('err')
			}
		},
		validate() {
			if (this.def.sections[this.sectionIndex].elements.every(e=>!e.required || (this.result[e.id] || this.result[e.id]===0))) {
				this.required = false
				return true
			} else {
				this.required = true
				return false
			}
		}
	}
}
</script>

<style lang='scss'>
.customOutcomeRender {
	@include fill;
	position: relative;
	padding: 0 $size-gutter * 2;

	.outcomeHead {
		position: relative;
		padding: $size-gutter * 4 $size-gutter * 4;
		.title { margin-top: 30px; margin-bottom: 50px; font-size: $size-font-large; color: $color-primary-accent; font-weight: bold; text-align: center; text-decoration: underline; & + .instructions { margin-top: $size-gutter * 4; }}
		.instructions { @include rich; }
	}

	.section {
		background: #fff;
		border: 1px solid $color-neutral-shadow;
	}

	.sectionHead {
		padding: $size-gutter * 4 $size-gutter * 4;
		background: $color-neutral-silver; 
		&:empty { display: none; }
		.title { font-size: $size-font-large; color: $color-primary-accent; font-weight: bold; text-align: center; text-decoration: underline; & + .desc { margin-top: $size-gutter * 4; }}
		.desc { @include rich; }
	}

	.elements > div {
		padding: $size-gutter * 3 $size-gutter * 4;
		padding-bottom: 0;
		display: flex;
		.no { 
			padding-right: $size-gutter * 3;
			padding-bottom: $size-gutter * 3;
			width: 44px;

			> div { 
				display: flex; 
				align-items: center; 
				justify-content: center; 
				font-size: $size-font-standard; 
				font-weight: bold; 
				color: $color-primary-accent; 
				margin-top: 1px;
			}
		}
		.question {
			flex: 1;
			padding-bottom: $size-gutter * 3;
			border-bottom: 1px solid $color-neutral;
			.desc {
				@include rich;
			}
		}
		&.required { 
			background: rgba($color-alert, 0.3); 
			.question { 
				border-bottom: 1px solid transparent; 
				> .desc:before { content: '[Required] '; font-weight: bold; color: $color-alert; }
			}
			.no > div { color: $color-alert; } 
		}
		&:last-child .question {
			border-bottom: none;
		}		
	}

	.controlBar { border: none; }

	&.preview {
		.elements > div { pointer-events: none; }
	}
}
</style>