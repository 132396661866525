<template>
<ElementHOC 
	:def='def' :pos='pos' 
	:autoNumber='autoNumber' 
	:canAddFormulaVals='def.choices.length'
	:hasFormulaVals='hasFormulaVals'
	:showId='hasFormulaVals'
	:active='active'
	:summable='summable'	
	:canToggleRequired='true'	
	:index='index'
	:errs='errs'	
	@addFormulaVals='addFormulaVals'
	@removeFormulaVals='removeFormulaVals'
	ref='hoc'	
	placeholder='Question...' 
	v-on='$listeners' 
	class='multipleChoice'
>
	<div class='choices'>
		<div v-for='(choice, i) in def.choices' :key='i'>
			<div class='blockButton'>
				<svg class='miniIcon choiceIcon'>
					<use xlink:href='#radio' />
				</svg>
			</div>				
			<TextInput 
				v-model='choice.desc' 
				:placeholder='`Choice ${i+1}...`' 
				maxlength='200' 
				class='choice'
				ref='choices'
				@submit='setNextActive(i, true)'
				@keyup.up='setPrevActive(i)'
				@keyup.down='setNextActive(i)'
			/>
			<template v-if='"val" in choice'>
				<span>＝</span>
				<TextInput 
					v-model='choice.val' 
					placeholder='Value...' 
					maxlength='4' 
					class='value'
					:numeric='true'
					@submit='setNextActive(i, true)'
					@keyup.up='setPrevActive(i)'
					@keyup.down='setNextActive(i)'
				/>
			</template>
			<CloseButton @click='delChoice(i)' />
		</div>
	</div>
	<div @click='addChoice' class='iconTextButton addChoice'>
		<svg class='miniIcon'>
			<use xlink:href='#plus' />
		</svg>
		<span class='label'>Choice</span>
	</div>	
</ElementHOC>
</template>

<script>
import { uniq } from 'lodash'
import TextInput from '@/components/common/TextInput'
import CloseButton from '@/components/common/buttons/Close'

import ElementHOC from './HOC'

export default {
	name: 'MultipleChoice',
	components: { ElementHOC, TextInput, CloseButton },
	props: ['def','pos','autoNumber','active','summable','index'],
	data() { return {
		errs: []
	}},	
	computed: {
		hasFormulaVals() {
			return !!this.def.choices.find(c=>'val' in c)
		}
	},
	methods: {
		setPrevActive(i) {
			if (i) this.$refs.choices[i-1].focus()
		},
		setNextActive(i, create) {
			if (i===this.def.choices.length-1 && create) this.addChoice()
			else if (i<this.def.choices.length-1) this.$refs.choices[i+1].focus()
		},
		addChoice() {
			const o = { desc: '' }
			if (this.hasFormulaVals) {
				o.val = this.def.choices[this.def.choices.length-1].val+1
			} else if (!this.def.choices.length) {
				o.val = 0
			}
			this.def.choices.push(o)
			this.$nextTick(()=>this.$refs.choices[this.$refs.choices.length-1].focus())
		},
		delChoice(index) {
			this.$delete(this.def.choices, index)
		},
		addFormulaVals() {
			this.def.choices.forEach((c,i) => this.$set(c, 'val', i))
		},
		removeFormulaVals() {
			this.def.choices.forEach((c,i) => this.$delete(c, 'val'))
		},		
		validate() {
			this.errs = []
			if (this.def.choices.length<2) this.errs.push('Minimum two choices required')
			if (!this.def.choices.every(c=>c.desc.trim())) this.errs.push('Choice description required')
			if (this.hasFormulaVals) {
				if (!this.def.choices.every(c=>Number.isInteger(c.val))) this.errs.push('Formula values required')
				else if (this.def.choices.map(c=>c.val).length !== uniq(this.def.choices.map(c=>c.val)).length) this.errs.push('Formula values must be unique')
			}
			const success = this.$refs.hoc.validate()
			return success && !this.errs.length
		}
	}
}
</script>

<style lang='scss'>
.customOutcomeEditor .multipleChoice {

	.choices {
		> div {
			position: relative;
			display: flex;
			align-items: center;
			.choice { flex: 5; }
			.value { flex: 1; }
			.choiceIcon {  color: $color-neutral-shadow; }
			> span { 
				font-size: $size-font-large; 
				font-weight: bold;
				color: $color-primary-accent;
			}
			&:hover {
				background: $color-neutral-silver; 
				.textInput { background: $color-neutral-silver; }
			}
		}
	}
	
	.addChoice {
		padding-left: $size-gutter * 2;
		width: fit-content;
	}

}
</style>