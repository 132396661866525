<template>
<div class='manageClientOutcomesHeader' @click.stop>
	<TableColumns 
		:module='module'
		:columns='[{
			label: $t("elements.cols.outcome"),
			selectable: true,
			sort: "type",
			flex: 4
		},{
			label: $t("elements.cols.outcomeLastSent"),
			selectable: true,
			sort: "lastSentOn",
			flex: 1.5
		},{
			label: $t("elements.cols.outcomeLastCompleted"),
			selectable: true,
			sort: "lastCompletedOn",
			flex: 1.5
		}, {
			label: $t("elements.cols.outcomeNextEmail"),
			selectable: true,
			sort: "nextEmailOn",
			flex: 2.75
		}]'
	/>
</div>
</template>

<script>
import TableColumns from '@/components/common/TableColumns'

export default {
	name: 'ClientOutcomesHeader',
	props: ['module'],
	components: { TableColumns }
}
</script>

<style lang='scss'>
.manageClientOutcomesHeader {
	padding-left: $size-gutter * 2;
	padding-right: $size-gutter * 2;
}
</style>