<template>
<div class='numberSlide'>
	<div v-if='index' class='no'><div>{{index}}.</div></div>
	<div class='question'>
		<div class='desc' v-html='def.desc' />
		<div class='slideWrapper'>
			<div v-if='def.labelMin' class='labelMin'>{{def.labelMin}}</div>
			<div class='slide'>
				<vue-slider
					:value='Number.isInteger(value) ? value : def.min'
					:marks='def.max-def.min<=10'
					:min='def.min'
					:max='def.max'
					:interval='1'
					:dotSize='20'
					:contained='true'
					:adsorb='true'
					@change='update'
				/>		
			</div>
			<div v-if='def.labelMax' class='labelMax'>{{def.labelMax}}</div>
		</div>
	</div>	
</div>
</template>

<script>
import VueSlider from 'vue-slider-component'

export default {
	name: 'NumberSlide',
	components: { VueSlider },
	props: ['value','def','index'],
	methods: {
		update(v) { this.$emit('input', v ) }
	}
}
</script>

<style lang='scss'>
.customOutcomeRender .numberSlide {

	$themeColor: $color-alert;
	$bgColor: $color-neutral-shadow !default;
	@import '~vue-slider-component/lib/theme/antd.scss';

	.slideWrapper {
		display: flex;
		align-items: center;
		margin: $size-gutter * 2 0;
		.slide { flex: 5; margin: 0 $size-gutter * 2; }
		.labelMin, .labelMax { flex: 1; font-size: $size-font-small; line-height: $size-lineHeight-small; }
		.labelMin { text-align: right; }
	}

}
</style>