<template>
	<div 
		@click='add()' 
		class='addQuestion iconTextButton neutral' 
		@mouseleave='showMenu=false' 
		@mouseenter='orientMenu'
		:class='{ menuTop: showMenuTop, menuBottom: !showMenuTop }'
	>
		<svg class='smallIcon'>
			<use xlink:href='#plus' />
		</svg>
		<span class='label'>Question</span>
		<div class='caret' @mouseenter='showMenu=true' @click.stop>
			<svg class='smallIcon'>
				<use xlink:href='#caret' />
			</svg>							
		</div>
		<div class='menu' :class='{ showMenu }'>
			<div class='miniButton' @click.stop='add("shortComment")'> 
				{{$t(`elements.outcomeQuestions.shortComment`)}}
			</div>
			<div class='miniButton' @click.stop='add("longComment")'> 
				{{$t(`elements.outcomeQuestions.longComment`)}}
			</div>							
			<div class='miniButton' @click.stop='add("checkbox")'> 
				{{$t(`elements.outcomeQuestions.checkbox`)}}
			</div>
			<div class='miniButton' @click.stop='add("multipleChoice")'> 
				{{$t(`elements.outcomeQuestions.multipleChoice`)}}
			</div>
			<div class='miniButton' @click.stop='add("number")'> 
				{{$t(`elements.outcomeQuestions.number`)}}
			</div>
			<div class='miniButton' @click.stop='add("numberSlide")'> 
				{{$t(`elements.outcomeQuestions.numberSlide`)}}
			</div>
		</div>						
	</div>
</template>

<script>
export default {
	name: 'AddElement',
	data() { return { showMenu: false, showMenuTop: false }},
	methods: {
		orientMenu(e) {
			const b = e.target.getBoundingClientRect()
			if (window.innerHeight-b.top<200) {
				this.showMenuTop = true
			} else {
				this.showMenuTop = false
			}
		},
		add(v) {
			this.$emit('add', v)
			this.showMenu=false
		}
	}
}
</script>

<style lang='scss'>
.customOutcomeEditor .addQuestion {
	position: relative;
	.caret {
		transition: opacity 200ms linear;
		opacity: 0;			
		pointer-events: none;	
		svg { position: relative; }
		position: absolute;
		top: 50%;
		right: $size-gutter * 2;
		background: $color-neutral;
		margin-top: -12px;
		width: 24px;
		height: 24px;
		border-radius: 12px; 
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.menu {
		position: absolute;
		z-index: 100;
		left: -1px;
		width: calc(100% + 2px);
		background: $color-neutral-silver;
		border: 1px solid $color-neutral-shadow;
		font-weight: normal;
		padding: $size-gutter;
		transition: opacity 200ms linear;
		opacity: 0;
		pointer-events: none;
		> div:not(:last-child) { margin-bottom: 1px; }
		> div { border-radius: 2px;  padding: 0 $size-gutter * 2; &:hover { background: $button-gradient-primary; color: #fff; }}	
		&.showMenu {
			opacity: 1;
			pointer-events: auto;
			> div { pointer-events: auto; }				
		}	
	}
	&.menuTop {
		.caret svg { top: -1px; }
		.menu { border-bottom: none; bottom: $size-control-height; }
	}
	&.menuBottom {
		.caret svg { top: 1px; transform: rotate(180deg); }
		.menu { border-top: none; box-shadow: 0 4px 6px -2px rgba(0,0,0,.08); top: $size-control-height; }
	}		
	&:hover .caret {
		opacity: 1;
		pointer-events: auto;
	}
}
</style>