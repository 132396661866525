<template>
<div class='inspectClientOutcome'>

	<div class='contentPanel'><div v-if='loaded'>

			<div class='actions'><template v-if='client && !client.archived'>
				<div class='iconTextButton' @click='resend'> 
					<svg class='smallIcon'>
						<use xlink:href='#email' />
					</svg>
					<span class='label'>Send Again</span>
				</div>
				<div class='iconTextButton' @click='showSchedule'> 
					<svg class='smallIcon'>
						<use xlink:href='#calendar' />
					</svg>
					<span class='label'>Schedule</span>
				</div>			
				<div class='iconTextButton' @click='showManual'> 
					<svg class='smallIcon'>
						<use xlink:href='#edit' />
					</svg>
					<span class='label'>Manually Fill</span>
				</div>		
			</template></div>

			<div class='main results'>

				<div class='tabButtons'>						
					<div 
						@click='tab=`results`'
						:class='{ selected: tab === `results`}'
						v-if='clientOutcome.results'
					>
						<span class='label'>{{$t('elements.tabs.results')}}</span>
						<div class='printChartButton blockButton' @click='printChart'>
							<svg class='smallIcon'>
								<use xlink:href='#print' />
							</svg>
						</div>								
					</div>
					<div 
						@click='tab=`history`'
						:class='{ selected: tab === `history`}'
					>
						<span class='label'>{{$t('elements.tabs.history')}}</span>					
					</div>
				</div>
				
				<header>
					<span>{{client.firstname}} {{client.lastname}}<br />{{outcomeDef.title}}</span>
					<div v-if='clientOutcome.results && tab==="results"' class='customOutcomeFormula'>
						<SelectInput v-model='customFormula'>
							<option v-for='(result, i) in clientOutcome.results' :key='i' :value='i'>{{result.desc}}</option>
						</SelectInput>
					</div>
				</header>

				<div class='chartWrapper'>
					<Chart 
						v-if='clientOutcome.results'
						:results='chartResults' 
						:styles='chartStyles' 
						:grid='true' 
						:type='clientOutcome.type'
						:key='renderKey'
						class='chart'
						@click='(index) => fetchResult(chartResults[index].id)'
					/>
					<div class='printWrapper' v-if='clientOutcome.results'><div>
						<Chart 
							:results='chartResults' 
							:grid='true' 
							:type='clientOutcome.type'
						/>
					</div></div>					
					<div v-if='tab==="history" || !clientOutcome.results' class='historyWrapper'>
						<History :outcomeId='id' :type='clientOutcome.type' :client='client' @selectResult='fetchResult' :formulaResults='clientOutcome.results' :key='renderKey' />
					</div>
				</div>
			</div>
<!--
			<div class='main deliveryDates'>
				<header>Schedule Delivery Dates</header>
				<div class='dateInputs'>
					<div class='days inputWrapper'>
						<SelectInput v-model='intervalType'>
							<option disabled selected value=''>{{$t('views.assignOutcome.chooseDates.phDates')}}</option>
							<option value='custom'>{{$t('views.assignOutcome.chooseDates.optSpecific')}}</option>
							<option value='every'>{{$t('views.assignOutcome.chooseDates.optDaily')}}</option>
							<option value='alt'>{{$t('views.assignOutcome.chooseDates.optAltDays')}}</option>
							<optgroup :label='$t(`views.assignOutcome.chooseDates.optWeekly`)'>
								<option value='Mo'>{{$t('views.assignOutcome.chooseDates.weekdays.mo')}}</option>
								<option value='Tu'>{{$t('views.assignOutcome.chooseDates.weekdays.tu')}}</option>
								<option value='We'>{{$t('views.assignOutcome.chooseDates.weekdays.we')}}</option>
								<option value='Th'>{{$t('views.assignOutcome.chooseDates.weekdays.th')}}</option>
								<option value='Fr'>{{$t('views.assignOutcome.chooseDates.weekdays.fr')}}</option>
								<option value='Sa'>{{$t('views.assignOutcome.chooseDates.weekdays.sa')}}</option>
								<option value='Su'>{{$t('views.assignOutcome.chooseDates.weekdays.su')}}</option>
							</optgroup>
							<optgroup :label='$t(`views.assignOutcome.chooseDates.optAltWeeks`)'>
								<option value='altMo'>{{$t('views.assignOutcome.chooseDates.weekdays.mo')}}</option>
								<option value='altTu'>{{$t('views.assignOutcome.chooseDates.weekdays.tu')}}</option>
								<option value='altWe'>{{$t('views.assignOutcome.chooseDates.weekdays.we')}}</option>
								<option value='altTh'>{{$t('views.assignOutcome.chooseDates.weekdays.th')}}</option>
								<option value='altFr'>{{$t('views.assignOutcome.chooseDates.weekdays.fr')}}</option>
								<option value='altSa'>{{$t('views.assignOutcome.chooseDates.weekdays.sa')}}</option>
								<option value='altSu'>{{$t('views.assignOutcome.chooseDates.weekdays.su')}}</option>
							</optgroup>
						</SelectInput>
					</div>
					<div class='until'>Until</div>
					<div class='pickEndDate inputWrapper'>
						<PickEndDate 
							v-model='clientOutcome.expiresOn' 
							:rows='1'
							:columns='3'
							:placeholder='$t(`views.assignOutcome.chooseDates.phEndDate`)'
							popOverPlace='bottom' 
						/>
					</div>
				</div>
				<PickReminders 
					v-model='clientOutcome.reminders' 
					:intervalType='intervalType'
					:expiresOn='clientOutcome.expiresOn'
				/>
			</div>

			<div class='main notes'>
				<Header>Add Notes</Header>
				<div class='label' v-html='$t("views.assignOutcome.addNotes.p1")' />
				<RichText 
					v-model='clientOutcome.notes' 
					:placeholder='$t(`views.assignOutcome.addNotes.ph`)'
					:maxlength='400'
				/>
			</div>					
-->

	</div></div>

	<div v-if='outcomeDef' class='previewPanel'>
		<Scroll>
			<div class='previewControls'>
				<svg class='smallIcon' @click='printOutcome'>
					<use xlink:href='#print' />
				</svg>
				<svg class='smallIcon' @click='exportOutcome'>
					<use xlink:href='#export' />
				</svg>
				<div v-if='mostRecent' class='mostRecent miniButton success'>{{$t('elements.labels.mostRecent')}}</div>	
				<div v-if='outcomeResult.created' class='updated'>{{date(outcomeResult.created)}}</div>
			</div>			
			<div class='previewNotes' v-if='visibleNotes' v-html='clientOutcome.notes' />
			<OutcomePreview :def='outcomeDef' :result='outcomeResult.result' :preview='true' class='preview' />
		</Scroll>
		<transition name='fade'><Loading v-show='previewWorking' /></transition>
	</div>			

	<div class='modalControls'>
		<div class='iconTextButton' @click='$emit("close")' >
			<svg class='smallIcon'>
				<use xlink:href='#cancel' />
			</svg>
			<span class='label'>Close</span>
		</div>		
	</div>

	<transition name='fade'><Loading v-show='working' /></transition>

</div>
</template>

<script>
import { cloneDeep, pick } from 'lodash'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
import axios from 'axios'
import printJS from 'print-js-updated'
import { smallModalMixin, largeModalMixin } from '@/components/common/mixins/modal'
import { dateTime } from '@/components/common/mixins/dateTime'
import { pollDownload } from '@/components/common/mixins/pollDownload'
import { outcomesApi } from '@/services/api/modules/clinician/outcomes'
import { clientOutcomesApi } from '@/services/api/modules/clinician/clientOutcomes'
import Loading from '@/components/common/Loading'
import Scroll from '@/components/common/Scroll'
import SelectInput from '@/components/common/SelectInput'
import RichText from '@/components/common/RichText'
import Client from '@/components/clinician/clients/modals/Client'
import PickReminders from '@/components/common/PickReminders'
import PickEndDate from '@/components/common/PickEndDate'
import OutcomePreview from '@/components/clinician/outcomes/engine/render'

import Manual from '@/components/clinician/AssignOutcomeToClient/Manual'
import Chart from './Chart'
import History from './History'
import Schedule from './Schedule'


export default {
	name: 'InspectOutcome',
	mixins: [dateTime, smallModalMixin, largeModalMixin, pollDownload],
	components: { Loading, Scroll, SelectInput, PickReminders, PickEndDate, Chart, History, RichText, OutcomePreview },
	props: ['id', 'sendNow'],
	data() { return { 
		clientOutcome: {
			type: null,
			created: null,
			enabled: null,
			expiresOn: null,
			reminders: null,
			notes: null,
			results: [],
		},
		outcomeDef: undefined,
		outcomeResult: { result: undefined },
		clientId: null,
		working: false,
		previewWorking: false,
		loaded: false,		
		tab: 'results',
		intervalType: null,
		customFormula: 0,
		renderKey: 0,
		chartStyles: {
			width: '100%',
			height: '100%',
			position: 'relative'
		}
	}},
	computed: {
		client() {
			return this.$store.state.clients[this.clientOutcome.clientId]
		},
		mostRecent() {
			if (this.outcomeResult && this.chartResults && this.chartResults.length) {
				const latest = this.chartResults.reduce((a, b) => {
					return new Date(a.created) > new Date(b.created) ? a : b
				})
				return latest && latest.id === this.outcomeResult.id
			} else {
				return null
			}

/*
			return (
				this.outcomeResult && this.chartResults && this.chartResults.length &&
		//		this.clientOutcome.results && this.clientOutcome.results.length &&
				this.outcomeResult.id === this.chartResults[this.clientOutcome.results.length - 1].id
			)
*/
		},
		visibleNotes() {
			return this.clientOutcome.notes && this.clientOutcome.notes.replace(/<[^>]+>/g, '').trim()
		},
		showSave() {
			return JSON.stringify(this.clientOutcome) !== this.saved
		},
		chartResults() {
			if (this.clientOutcome && this.clientOutcome.results && this.clientOutcome.results[this.customFormula]) {
				//if (this.clientOutcome.type === 'custom') {
					return this.clientOutcome.results[this.customFormula].results
				//} else {
				//	return this.clientOutcome.results
				//}
			} else {
				return null
			}
		}
	},
	methods: {
		close() { this.$emit('close') },
		async fetch() {
			this.working = true
			const [err, result] = await clientOutcomesApi.touch('fetch', { id: this.id })
			//console.log(result)
			if (err) return this.$emit('close')
			const { type, def, clientId, created, enabled, expiresOn, reminders, instructions, formulaResults, lastResult } = result
			const outcome = {
				type, clientId, created, enabled, 
				results: formulaResults,
				notes: instructions,
				expiresOn: new Date(expiresOn) < Date.now() ? new Date((+new Date) + 2678400000) : new Date(expiresOn),
				reminders: reminders.map(reminder => {
					const d = new Date(reminder)
					d.setHours(24,0,0,0)
					return d
				})
			}
			this.clientOutcome = outcome
			this.saved = JSON.stringify(this.clientOutcome)
			this.outcomeDef = def // type === 'custom' ? def : await fetchDef(type)
			if (lastResult) this.outcomeResult = lastResult
			this.working = false
			this.loaded = true
		},

		async fetchResult(id) {
			this.previewWorking = true
			const [err, result] = await clientOutcomesApi.touch('fetchResults', { id })
			if (!err) this.outcomeResult = result
			this.previewWorking = false
		},

		save() {
			const fn = async () => {
				this.working = true
				const payload = { 
					id: this.id,
					expiresOn: this.clientOutcome.expiresOn, 
					reminders: this.clientOutcome.reminders,
					//instructions: this.clientOutcome.notes,
					enabled: true
				}
				const [err] = await clientOutcomesApi.touch('update', payload)
				if (!err) {
					this.$store.dispatch('manageClientOutcomes/fetchTop')
					this.$store.dispatch('flash/showAction', 'scheduleStarted')
				}
				this.working = false
				this.$emit('close')
			}
			if (
				this.client.email && 
				this.client.portalKey && 
				!this.client.emailErr
			) fn()
			else this.showSmallModal(Client, {
				client: this.client, 
				emailRequired: true,
				onSave: fn
			})	
		},

		resend() {
			const fn = async () => {
				const today = new Date()
				today.setHours(0,0,0,0)
				const expiresOn = this.clientOutcome.expiresOn && this.clientOutcome.expiresOn.getTime() > today.getTime() ? this.clientOutcome.expiresOn : dayjs().add(3, 'month').utc(true).format()
				const remindersIncludesToday = !!this.clientOutcome.reminders.find(reminder => reminder.getTime() === today.getTime())
				const reminders = remindersIncludesToday ? this.clientOutcome.reminders : [today].concat(this.clientOutcome.reminders)
				const payload = { 
					id: this.id,
					expiresOn, 
					reminders,
					instructions: this.clientOutcome.notes,
					enabled: true
				}
				this.working = true
				const [err] = await clientOutcomesApi.touch('update', payload)
				if (!err) {
					this.$store.dispatch('manageClientOutcomes/fetchTop')
					this.$store.dispatch('flash/showAction', { code: 'emailSent', vars: { email: this.client.email }})
				}
				this.working = false
				this.$emit('close')
			}
			if (
				this.client.email && 
				this.client.portalKey &&
				!this.client.emailErr
			) fn()
			else this.showSmallModal(Client, {
				client: this.client, 
				emailRequired: true,
				onSave: fn
			})	
		},

		printChart() {
			const canvas = this.$el.querySelector('.printWrapper canvas')
			const dataUrl = canvas.toDataURL()
			const windowContent = `
				<!DOCTYPE html>
				<html>
				<head>
					<title>Print canvas</title>
					<style>
						body { padding: 50px; display: flex; align-items: center; justify-content: center; font-family: Helvetica; }
						h3 { text-align: center; }
						img { margin: 20px auto; width: 100% }
						@page { size: landscape; }
					</style>
				</head>
				<body><div>
					<h3>
						${this.client.firstname} ${this.client.lastname}<br />
						${this.$t(`elements.outcomes.${this.clientOutcome.type}`)}
					</h3>
					<img src='${dataUrl}'>
				</div></body>
				</html>
			`
			const printWin = window.open()
			printWin.document.open()
			printWin.document.write(windowContent)
			printWin.document.close()
			printWin.focus()
			setTimeout(() => {
				printWin.print()
				printWin.close()
			}, 0)
		},

		async printOutcome() {
			this.previewWorking = true
			const payload = {
				outcomeType: this.clientOutcome.type,
				def: this.outcomeDef,
				result: { created: this.outcomeResult.created, ...this.outcomeResult.result },
				client: pick(this.client, ['firstname','lastname','lang'])
			}
			const [err, result] = await outcomesApi.touch('export', payload)
			if (!err) {
				const { url, readyId } = result
				await this.poll(readyId)
				printJS(url)
			}
			this.previewWorking = false	
		},

		async exportOutcome() {
			this.previewWorking = true
			const payload = {
				outcomeType: this.clientOutcome.type,
				def: this.outcomeDef,
				result: { created: this.outcomeResult.created, ...this.outcomeResult.result },
				client: pick(this.client, ['firstname','lastname','lang'])
			}			
			const [err, result] = await outcomesApi.touch('export', payload)
			if (!err) {
				const { url, readyId } = result
				await this.poll(readyId)
				const response = await axios.get(url, { responseType: 'blob' })
				const href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
				const link = document.createElement('a')
				link.setAttribute('download', `${this.client.lastname}-${this.clientOutcome.type}-${this.outcomeResult.created.split('T')[0]}.pdf`)
				link.href = href
				link.click()
			}
			this.previewWorking = false		
		},

		async showManual() {
			this.working = true
			const [err, result] = await clientOutcomesApi.touch('fetchHistory', { id: this.id })
			this.working = false 
			const disableDates = result.results.map(r => new Date(r.created))
			/*
			if (this.clientOutcome.results) {
				if (this.clientOutcome.results[0].results) {
					disableDates = this.clientOutcome.results.map(r => r.results.map(r2 => new Date(r2.created))).flatten()
				} else {
					disableDates = this.clientOutcome.results.map(r => new Date(r.created))
				}
			}
			*/
			this.showLargeModal(
				Manual, 
				{ 
					def: this.outcomeDef, client: this.client, verify: true,
					disabled: disableDates,
					sticky: true,
					fn: async (result, backdate) => {
						this.working = true
						const [err, manualResult] = await clientOutcomesApi.touch('createResult', { id: this.id, result, backdate })
						if (!err) {
							await this.fetch()
							this.outcomeResult = manualResult
							this.$store.dispatch('manageClientOutcomes/fetchTop')
							this.renderKey++
						} else {
							this.working = false
						}
					}
				}, 
				'800px' 
			)
		},

		async showSchedule() {

			this.showSmallModal(
				Schedule, 
				{ 
					expiresOn: this.clientOutcome.expiresOn,
					reminders: this.clientOutcome.reminders,
					fn: async (reminders, expiresOn) => {
						this.clientOutcome.reminders = reminders
						this.clientOutcome.expiresOn = expiresOn
						await this.save()
						/*
						this.working = true
						const [err, manualResult] = await clientOutcomesApi.touch('createResult', { id: this.id, result, backdate })
						if (!err) {
							await this.fetch()
							this.outcomeResult = manualResult
							this.$store.dispatch('manageClientOutcomes/fetchTop')
							this.renderKey++
						} else {
							this.working = false
						}
						*/
					}
				}, 
				'800px' 
			)

		}
	},
	watch: {
		chartResults() { this.renderKey++ }
	},
	mounted() {
		this.fetch()
		this.$store.commit('notifications/markRead', { outcomeId: this.id })
		window.addEventListener('hashchange', this.close) 		
	},
	beforeDestroy() {
		window.removeEventListener('hashchange', this.close) 
	},
	renderError (h, err) {
		alert('Error rendering Outcome - please contact support@simpleset.net')
		this.$emit('close')
		//return h('pre', { style: { color: 'red' }}, err.stack)
	}
}
</script>

<style lang='scss'>
.inspectClientOutcome {
	@include fill;
	display: grid;
	grid-template-rows: 1fr auto;
	grid-template-columns: 2fr 1fr;
	background: $color-neutral-accent;
	grid-column-gap: $size-gutter * 4;
	padding-top: $size-gutter * 4;

	.contentPanel {
		position: relative;
		margin-left: $size-gutter * 4;
		margin-bottom: $size-gutter * 4;
		> div {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: grid;
			grid-template-rows: auto 1fr;
		}

		.actions {
			display: flex;
			background: $color-neutral-silver;
			align-items: center;
			> div { flex: 1; 			margin-bottom: $size-gutter * 2;
}
		}

		.main { 
			&:not(:last-child) { margin-bottom: $size-gutter * 4; }
			background: #fff;
			border: 1px solid $color-neutral-shadow;
			box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);		
			header {
				text-align: left;
				line-height: $size-lineHeight-large;
				color: $color-primary-accent;
			}						
		}

	}

	.results {
		display: grid;
		grid-template-rows: auto auto 1fr;


		.tabButtons {
			.printChartButton { position: absolute; top: 0; right: 0; color: $color-black; }
			.selected { background: #fff; border-bottom: none; .printChartButton { display: flex; }}
			.addResultButton { flex: 0.5;}
		}	

		header { 
			margin: 0 $size-gutter * 4; 
			display: flex;
			> * { flex: 1; }
			.customOutcomeFormula {}
		}

		.chartWrapper {
			padding: $size-gutter * 4;
			padding-top: 0;
			position: relative;

			canvas { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

			.historyWrapper { background: #fff; position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
			.printWrapper {
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 0;
				overflow: hidden;
				> div { width: 750px; height: 1000px; position: relative; }
			}			
		}
	}

	.deliveryDates {
		padding: $size-gutter * 4;
		padding-top: 0; 
		.dateInputs {
			display: flex;
			align-items: center;
			margin-bottom: $size-gutter * 4;
			.days {
				margin-right: $size-gutter * 2;
				width: 350px;
			}
			.until {
				font-size: $size-font-standard;
				margin-right: $size-gutter * 2;
			}
			.pickEndDate { 
				input { width: 150px; }
			}
		}
	}	

	.notes {
		padding: $size-gutter * 4;
		padding-top: 0; 		
		.label {
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			b { color: $color-primary-accent; }
			margin-bottom: $size-gutter * 4;
		}
		.richText {
			border: 1px solid $color-neutral-shadow;
			width: 100%;
		}
	}

	.previewPanel {
		border: 1px solid $color-neutral-shadow;
		background: #fff;
		box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);
		position: relative; 
		margin-right: $size-gutter * 4;
		margin-bottom: $size-gutter * 4;

		.previewControls {
			display: flex;
			align-items: center; 
			height: $size-control-height;
			svg { margin-left: $size-gutter * 2; cursor: pointer; }
			.mostRecent { margin-left: $size-gutter * 2; }
			.updated { margin-left: auto; padding-right: $size-gutter * 4; font-size: $size-font-standard; }
		}

		.previewNotes {
			background: $color-focus;
			margin: $size-gutter * 4;
			margin-top: $size-gutter * 4 + 1;
			margin-bottom: $size-gutter * 2;
			padding: $size-gutter * 2;
			@include rich;
			padding-top: 12px;
			padding-bottom: 13px;
		}


		.preview {
			padding: 0;
			> div { border: none; }
			.submitButton { display: none; }
		}
	}

	.modalControls {
		grid-column: 1 / -1 ;
	}

}
</style>
