var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"manageClientOutcomesHeader",on:{"click":function($event){$event.stopPropagation();}}},[_c('TableColumns',{attrs:{"module":_vm.module,"columns":[{
			label: _vm.$t("elements.cols.outcome"),
			selectable: true,
			sort: "type",
			flex: 4
		},{
			label: _vm.$t("elements.cols.outcomeLastSent"),
			selectable: true,
			sort: "lastSentOn",
			flex: 1.5
		},{
			label: _vm.$t("elements.cols.outcomeLastCompleted"),
			selectable: true,
			sort: "lastCompletedOn",
			flex: 1.5
		}, {
			label: _vm.$t("elements.cols.outcomeNextEmail"),
			selectable: true,
			sort: "nextEmailOn",
			flex: 2.75
		}]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }