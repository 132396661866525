import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetchList: '/',
		fetch: '/${id}'
	},
	post: {
		create: '/',
		export: '/${outcomeType}/pdf',
	},
	put: {
		update: '/${id}',
		move: '/${outcomeId}/folder/${folderId}'
	},
	delete: {
		destroy: '/${id}'
	}
}

export const outcomesApi = buildApi('outcomes', routes, '/clinician/outcomes')

