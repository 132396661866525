<template>
<div class='shortComment'>
	<div v-if='index' class='no'><div>{{index}}.</div></div>
	<div class='question'>
		<div class='desc' v-html='def.desc' />
		<TextInput :value='value' @input='update' placeholder='Type here...' maxlength='100' />
	</div>
</div>
</template>

<script>
import TextInput from '@/components/common/TextInput'	

export default {
	name: 'ShortComment',
	components: { TextInput },
	props: ['value','def','index'],
	methods: {
		update(v) { this.$emit('input', v )}
	}
}
</script>

<style lang='scss'>
.customOutcomeRender .shortComment {

	.textInput { border: 1px solid $color-neutral-shadow; margin-top: $size-gutter * 2; }
	&.required .textInput { border-color: $color-alert; }

}
</style>