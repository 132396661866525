<template>
<div class='element' @click.stop='onClick' @mouseup='onMouseUp' :class='{ active, err: allErrs.length, dragHandle: !active, summable }'>
	<div class='header controlBar'>
		<div class='blockButton dragHandle'>
			<svg class='smallIcon'>
				<use xlink:href='#dragY' />
			</svg>
		</div>
		<div class='blockButton blur' @click.stop='blur'>
			<svg class='smallIcon'>
				<use xlink:href='#angleUp' />
			</svg>
		</div>		
		<div v-if='def.type==="textBlock"' class='changeType'>Text Block</div>
		<div v-else-if='def.type==="formula"' class='changeType'>Formula</div>
		<TypeInput 
			v-else
			:type='def.type' 
			@changeType='type=>$emit("changeType", type, pos)'
			class='changeType'
		/>		
		<div @click='$emit("copy", pos)' class='iconTextButton neutral'>
			<svg class='smallIcon'>
				<use xlink:href='#copy' />
			</svg>
			<span class='label'>Duplicate</span>
		</div>	
		<CloseButton @click='$emit("del", pos)' />
	</div>
	<div class='desc'>
		<div v-if='autoNumber' class='autoNumber'><template v-if='index'>{{index}}.</template></div>
		<!--
		<RichText 
			v-model='def.desc' 
			:placeholder='placeholder' 
			:maxlength='300'
			:plain='true'
			@focus='$emit("setActive", def.id)'
			@keyup.up='$emit("setPrevActive", pos)'
			@keyup.down='$emit("setNextActive", pos)'
			ref='desc'
		/>
	-->
		<RichText 
			v-model='def.desc' 
			:placeholder='placeholder' 
			:maxlength='descMaxLength || 300'
			:plain='true'
			@focus='$emit("setActive", def.id)'
			@keyup.enter='e=>$emit("setNextActive", pos, !["textBlock","formula"].includes(def.type))'
			ref='desc'
		/>
		<div v-if='showId' class='internalId' @click.stop='copyId' @mousedown.stop>
			<span>ID: {{def.id}}</span>
			<svg class='miniIcon'>
				<use xlink:href='#copy' />
			</svg>		
		</div>		
	</div>	
	<div class='custom' :class='{ padded: autoNumber }'>
		<slot />
	</div>
	<div v-if='allErrs.length' class='errs' :class='{ padded: autoNumber }'>
		<div v-for='(err, i) in allErrs' :key='i'>{{err}}</div>
	</div>
	<div class='footer'>
		<div v-if='canToggleRequired' class='required'>
			<DraggableSwitch
				:value='def.required'
				@input='$set(def, "required", !def.required)'
			/>
			<span v-if='def.required' class='label'>Required</span>
			<span v-else class='label'>Not Required</span>			
		</div>		
		<div v-if='canAddFormulaVals && !hasFormulaVals' class='formulaVals iconTextButton' @click='$emit("addFormulaVals")'>
			<svg class='miniIcon'>
				<use xlink:href='#plus' />
			</svg>
			<span class='label'>Formula Values</span>
		</div>		
		<div v-else-if='canAddFormulaVals && hasFormulaVals' class='formulaVals iconTextButton' @click='$emit("removeFormulaVals")'>
			<svg class='miniIcon'>
				<use xlink:href='#minus' />
			</svg>
			<span class='label'>Formula Values</span>
		</div>				
	</div>
</div>
</template>

<script>
const copy = require('copy-to-clipboard')
import RichText from '@/components/common/RichText'
import DraggableSwitch from '@/components/common/DraggableSwitch'
import CloseButton from '@/components/common/buttons/Close'
import TypeInput from './TypeInput'

export default {
	name: 'ElementHOC',
	components: { RichText, DraggableSwitch, CloseButton, TypeInput },
	props: ['def','pos','autoNumber','placeholder','canAddFormulaVals','canToggleRequired','hasFormulaVals','showId','active','summable','index','errs','descMaxLength'],
	data() { return {
		innerErrs: []
	}},
	computed: {
		allErrs() { 
			const errs = this.errs || []
			return [ ...this.innerErrs, ...errs ]
		}
	},
	methods: {
		onClick(e) {
			if (!e.ctrlKey && !e.metaKey) {
				this.$emit("setActive", this.def.id)
			}
		},
		onMouseUp(e) {
			if ((e.ctrlKey || e.metaKey) && this.showId && !this.active) {
				this.$emit("setSum", this.def.id)
			}
		},		
		onDelete(e) {
			if (!this.def.desc) {
				this.$emit("del", this.pos)
				this.$emit("setPrevActive", this.pos)				
			}
		},
		addBlurListener() {
			this.removeBlurListener()
			document.addEventListener('click', this.blur)
		},
		removeBlurListener() {
			document.removeEventListener('click', this.blur)
		},
		blur() {
			this.$emit('blur')
		},
		copyId() {
			copy(this.def.id)
			this.$store.dispatch('flash/showAction', 'copyToClipboard')
		},
		validate() {
			this.innerErrs = []
			if (!this.def.desc || !this.def.desc.replace(/<[^>]+>/g, '').trim()) this.innerErrs.push('Question description required')
			return !this.innerErrs.length
		}
	},
	watch: {
		active(a,b) { 
			if (a) {
				this.$refs.desc.focus(true) 
				this.addBlurListener()
			} else {
				this.removeBlurListener()
			}
			if (!a && b && this.innerErrs.length && this.$slots.default) this.$slots.default[0].context.validate()
		//	if (!a && b && this.innerErrs.length) this.innerErrs = []
		}
	},
	mounted() {
		if (!this.$el.classList.contains('dragging')) this.$el.classList.add('animate')
		if (this.active) {
			this.$refs.desc.focus() 
			this.addBlurListener()
		}
	},
	beforeDestroy() {
		this.removeBlurListener()
	}
}
</script>

<style lang='scss'>
.customOutcomeEditor {

	.element {

		&.active { 
			border: 1px solid $color-neutral-shadow;
			box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);
			margin: $size-gutter * 2 0; 
		}
		&.summable {
			background: $color-focus !important;
			div { background: $color-focus !important; }
		}
		&:not(.active) {
			cursor: pointer;
			.header, .custom, .footer { display: none; }
			.ProseMirror { transition: none; }
			.richText { pointer-events: none; }
			.internalId { position: relative; z-index: 20; }
			.ProseMirror { 
				p:first-child:before {
					font-weight: bold;
					color: $color-primary-accent;
				}
			}
			&.shortComment .ProseMirror p:first-child:before { content: '[Short Comment]  '; }
			&.longComment .ProseMirror p:first-child:before { content: '[Long Comment]  '; }
			&.checkbox .ProseMirror p:first-child:before { content: '[Checkbox]  '; }
			&.multipleChoice .ProseMirror p:first-child:before { content: '[Multiple Choice]  '; }
			&.number .ProseMirror p:first-child:before { content: '[Number]  '; }
			&.numberSlide .ProseMirror p:first-child:before { content: '[Number Slide]  '; }
			&.textBlock .ProseMirror p:first-child:before { content: '[Text Block]  '; }
			&.formula .ProseMirror p:first-child:before { content: '[Formula]  '; }

			&:not(.err):hover { 
				background: $color-neutral-silver; 
				.ProseMirror { 
					background: $color-neutral-silver; 
				}
			}	
		}

		&.err {
			&:not(.active) {
				.autoNumber, .desc { color: $color-alert !important; }
				.ProseMirror p:first-child:before { color: $color-alert !important; }
			}
			/*
			border: $size-gutter * 2 solid rgba($color-alert, 0.3);
			margin: $size-gutter * 2 0;
			background: rgba($color-alert, 0.3);
			*/

		}

		background: #fff;
		position: relative;

		.header {
			border: none;
			.dragHandle { cursor: grab; color: $color-neutral-shadow; }
			.blur { color: $color-neutral-shadow; }
			.changeType { 
				flex: 2; 
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: $size-font-standard;
				color: $color-primary-accent;
			}
		}


		.desc {
			display: flex; 
			.autoNumber {
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: $size-font-standard;
				font-weight: bold;
				color: $color-primary-accent;
				width: 44px;
				height: $size-control-height;
			}
			.richText {
				flex: 1; 
			}
		}

		.custom, .errs {
			&.padded { padding-left: 44px; }
		}

		.errs {
			padding: $size-gutter * 2;
			padding-top: 0;
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			color: $color-alert;
			> div { padding-left: $size-gutter * 2; }
		}

		.footer {
			background: #fff;
			text-align: right;
			display: flex;
			align-items: center;
			height: $size-control-height;
			span { margin-left: auto; }

			.required {
				display: flex;
				align-items: center;
				white-space: no-wrap;
				margin-right: auto;
				margin-left: $size-gutter * 2;
				.label { margin-left: $size-gutter * 2; font-size: $size-font-standard; }
			}

			.formulaVals {
				margin-left: auto;
			}

			&:empty { display: none; }
		}

		.internalId {
			cursor: pointer;
			display: flex;
			align-items: center;
			color: $color-primary-accent;
			height: $size-control-height;
			padding: 0 $size-gutter * 2;
			> span { margin-right: $size-gutter; }
		}		

		&.animate {

			&:after {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				pointer-events: none;
				content: '';
				background: $color-focus;
				animation: fadein .5s;
				opacity: 0;
			}

		}

	}

	@keyframes fadein {
		from { opacity: 1; }
		to   { opacity: 0; }
	}	

}
</style>