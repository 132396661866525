var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clientsChooser"},[_c('div',{staticClass:"topPanel"},[_c('SearchInput',{attrs:{"placeholder":_vm.$t("views.clientsManager.phSearchClients")},on:{"input":_vm.fetchSearch,"submit":_vm.fetchSearchImmediate},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"contentPanel"},[_c('RecycleScroller',{staticClass:"recycleScroller",attrs:{"items":_vm.items,"item-size":40,"buffer":500},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('ListItem',{attrs:{"item":item,"itemKey":item.id},on:{"click":_vm.cb}})]}}])},[_c('template',{slot:"before"},[_c('div',{staticClass:"controls"},[_c('div',[_c('EnabledButton',{staticClass:"neutral tagPrimary addClientButton",attrs:{"svg":"#plus","label":_vm.$t("elements.buttons.client")},on:{"click":_vm.addClient}})],1),_c('div',[_c('div',{staticClass:"clientMax"},[_c('span',[_vm._v("Clients: "+_vm._s(_vm.$store.state.saveClients.max))]),(_vm.teams.length)?_c('span',[_vm._v("   ("+_vm._s(_vm.teams.map(t=>t.title).join(", "))+") ")]):_vm._e()])])])]),(_vm.total)?_c('template',{slot:"sticky"},[_c('TableColumns',{attrs:{"columns":[{
						label: _vm.$t("elements.cols.client"),
						flex: 1
					},{
						label: "Last Seen by",
						flex: 1
					}]}})],1):_vm._e(),_c('template',{slot:"after"},[_c('AtBottom',{attrs:{"action":_vm.fetchNext}})],1)],2),_c('transition',{attrs:{"name":"fade"}},[_c('Loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.working),expression:"working"}]})],1)],1),_c('div',{staticClass:"modalControls"},[_c('CancelButton',{on:{"click":function($event){return _vm.$emit("close")}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }