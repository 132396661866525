<template>
<div class='textBlock'>
	<div class='question'>
		<div class='desc' v-html='def.desc' />
	</div>	
</div>
</template>

<script>
export default {
	name: 'TextBlock',
	props: ['def']
}
</script>

<style lang='scss'>
.customOutcomeRender .textBlock {
	.question { border-bottom: none !important; }
	background: $color-neutral-silver;
	margin-top: $size-gutter * 4;
}
</style>