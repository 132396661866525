<template>
<ElementHOC 
	:def='def' 
	:pos='pos' 
	:autoNumber='autoNumber' 
	:canToggleRequired='true'
	:active='active'
	:index='index'
	ref='hoc'			
	placeholder='Question...'
	class='longComment' 
	v-on='$listeners'
>
</ElementHOC>
</template>

<script>
import ElementHOC from './HOC'

export default {
	name: 'LongComment',
	components: { ElementHOC },
	props: ['def','pos','autoNumber','active','index'],
	methods: {
		validate() {
			return this.$refs.hoc.validate()
		}
	}
}
</script>

<style lang='scss'>
</style>