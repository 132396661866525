<template>
<div class='pickEndDate'>
	<DateInput 
		mode='single' 
		:minDate='minDate'
		:maxDate='maxDate'
		:attributes='attributes' 
		:popOverPlace='popOverPlace'
		:rows='rows'
		:columns='columns'
		:placeholder='placeholder'
		v-model='innerValue' 
		@input='onInput'
	/>
	<div class='calendarIcon'>
		<svg class='smallIcon'>
			<use xlink:href='#calendar' />
		</svg>
	</div>
</div>
</template>

<script>
import dayjs from 'dayjs'
import DateInput from '@/components/common/DateInput'

export default {
	name: 'PickEndDate',
	props: ['popOverPlace', 'value', 'rows', 'columns', 'placeholder', 'created'],
	components: { DateInput },
	data() { return {
		minDate: new Date(),
		maxDate: new Date(dayjs(this.created).add(6, 'month')),
		innerValue: this.value
	}},
	computed: {
		attributes() {
			return [{
				key: 'today',
				highlight: true,
				dates: {
					start: new Date(),
					end: this.innerValue || new Date()
				}
			}]
		}
	},
	methods: {
		onInput(value) {
			this.$emit('input', value)
		}
	}
}
</script>

<style lang='scss'>
.pickEndDate {
	position: relative;

	.calendarIcon {
		position: absolute;
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		padding: 0 $size-gutter * 2;
		pointer-events: none;

		svg { color: $color-primary-accent; }
	}
}
</style>

