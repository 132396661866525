<template>
<div class='latestOutcomes' :key='renderKey'>
	<div class='header'>{{$t('views.clientsManager.lblRecentOutcomes')}}</div>
	<div class='grid'>
		<div 
			v-for='(outcome) of $store.state.manageClientOutcomes.latest' 
			class='summary'
			:key='outcome.id'
			@click='() => openOutcome(outcome.id)'
		>
			<div class='chartWrapper'>
				<Chart 
					v-if='results(outcome)'
					:results='results(outcome)' 
					:grid='false'
					:styles='{ position: "relative", height: "100%", width: "100%" }'
					:type='outcome.type'
				/>
				<div v-else class='notGraphable'>Not graphable</div>
			</div>
			<div class='chartHeader'><div>{{outcome.type === 'custom' ? getCustomTitle(outcome.id) : $t(`elements.outcomes.${outcome.type}`)}}
				<br /><b v-if='results(outcome)'>{{$t('elements.labels.latest')}}: {{results(outcome)[results(outcome).length-1].value}} {{$t('elements.parts.on')}} {{date(results(outcome)[results(outcome).length-1].created, false)}}</b>
			</div></div>
		</div>
	</div>
</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import { largeModalMixin } from '@/components/common/mixins/modal'
import Chart from '@/components/clinician/InspectClientOutcome/Chart'
import EditOutcome from '@/components/clinician/InspectClientOutcome'

export default {
	name: 'LatestOutcomes',
	mixins: [largeModalMixin, dateTime],
	components: { Chart },
	data() { return { renderKey: 0 }},
	computed: {
		lang() {
			return this.$store.state.profile.user.lang
		}
	},
	methods: {
		openOutcome(id) {
			this.showLargeModal(EditOutcome, { id, sticky: true }, '100%') 
		},
		getCustomTitle(id) {
			const o = this.$store.state.manageClientOutcomes.outcomes[id]
			return o ? o.customTitle : ''
		},
		results(outcome) {
			if (outcome.formulaResults) {
				const o = outcome.formulaResults.find(r=>r.results)
				return o ? o.results : false
			} else {
				return false
			}
			//} else {
			//	return outcome.formulaResults
			//}
		}
	},
	watch: {
		lang(a, b) {
			if (a !== b) this.renderKey++
		},
		'$store.state.manageClientOutcomes.latest': {
			handler() { this.renderKey++ },
			deep: true
		}
	}
}
</script>

<style lang='scss'>
.latestOutcomes {
	margin: $size-gutter * 2;

	.header {
		height: $size-control-height;
		display: flex;
		align-items: center;
		font-size: $size-font-standard;
		font-weight: bold;
		padding-left: $size-gutter * 2;
	}

	.grid {
		display: grid;
		grid-gap: 14px;
		grid-template-columns: 1fr 1fr 1fr;
		padding: 1px;

		.summary {
			background: $color-white;
			position: relative;
			padding-top: 60%;
			outline: 1px solid $color-neutral-shadow;
			min-width: 0;
			cursor: pointer;
			box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);		

			&:hover {
				background: $color-focus;
				.chartHeader { background: $color-focus; }
			}

			.chartWrapper {
				position: absolute;
				top: 0;
				left: 3.5px;
				width: calc(100% - 7px);
				height: calc(100% - #{$size-control-height});
			}

			.notGraphable {
				width: 100%;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: $size-font-standard;
				color: $color-neutral-shadow;
				font-style: italic;
			}

			.chartHeader {
				height: 50px; /* $size-control-height; */
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 $size-gutter * 2;
				font-size: $size-font-small;
				background: $color-neutral-silver;	

				b { color: $color-primary-accent; }

				> div {
					white-space: nowrap; 
					text-overflow: ellipsis; 
					overflow: hidden;	
					text-align: center;
					line-height: $size-lineHeight-small;
				}
			}
		}
	}
}

</style>
