<template>
<div class='dateInput' :class='{ inline: mode !== "single" }'>
	<DatePicker 
		nav-visibility='hidden'
		:mode='mode' 
		:is-inline='mode !== "single"' 
		:columns='columns || 2' 
		:rows='rows || 2' 
		:min-date='minDate'
		:max-date='maxDate'
		:attributes='attributes' 
		:disabled-dates='disabled'
		:locale='locale'
		:popover='{ placement: popOverPlace || `bottom-start`, visibility: `click`}'
		:input-props='{ placeholder: placeholder }'
		is-expanded
		v-model='innerValue' 
	/>
</div>
</template>

<script>
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
	name: 'DateInput',
	components: { DatePicker },
	props: ['mode', 'value', 'minDate', 'maxDate', 'attributes', 'columns', 'rows', 'popOverPlace', 'placeholder', 'disabled'],
	data() { return {
		innerValue: this.value
	}},
	computed: {
		locale() {
			return this.$store.state.profile.user.lang
		}
	},
	watch: {
		innerValue(value) {
			this.$emit('input', value)
		},
		value(value) {
			this.innerValue = value
		}
	}
}
</script>

<style lang='scss'>
.dateInput {

	outline: none;
	font-size: $size-font-standard;

	&:not(.inline) {
		height: $size-control-height + 2px;
		border: 1px solid $color-neutral-shadow;
	}
	
	input { 
		border:  none;
		font-size: $size-font-standard;
		height: $size-control-height;
		background: none;
		&::placeholder {
			color: $color-black;
		}
	}

	> div {
		/*
		border: 1px solid $color-neutral-shadow;
		border-radius: 0;
		*/
		border: none;
	}

	.vc-popover-content {
		border: 1px solid $color-neutral-shadow;
		border-radius: 0;
		left: -1px;
		z-index: 99;
	}

	.vc-day-content {
		font-size: $size-font-standard;
		margin: 0;
	}
}
</style>

