<template>
<div class='elementTypeInput iconTextButton neutral' @click='showMenu=true'>

	<span class='label'>{{$t(`elements.outcomeQuestions.${type}`)}}</span>

	<svg class='smallIcon'>
		<use xlink:href='#caret' />
	</svg>

	<div class='menu' :class='{ showMenu }' @mouseleave='startCloseTimer' @mouseenter='stopCloseTimer'>
		<div class='miniButton' :class='{ primary: type==="shortComment" }' @click.stop='changeType("shortComment")'> 
			{{$t(`elements.outcomeQuestions.shortComment`)}}
		</div>
		<div class='miniButton' :class='{ primary: type==="longComment" }' @click.stop='changeType("longComment")'> 
			{{$t(`elements.outcomeQuestions.longComment`)}}
		</div>							
		<div class='miniButton' :class='{ primary: type==="checkbox" }' @click.stop='changeType("checkbox")'> 
			{{$t(`elements.outcomeQuestions.checkbox`)}}
		</div>
		<div class='miniButton' :class='{ primary: type==="multipleChoice" }' @click.stop='changeType("multipleChoice")'> 
			{{$t(`elements.outcomeQuestions.multipleChoice`)}}
		</div>
		<div class='miniButton' :class='{ primary: type==="number" }' @click.stop='changeType("number")'> 
			{{$t(`elements.outcomeQuestions.number`)}}
		</div>		
		<div class='miniButton' :class='{ primary: type==="numberSlide" }' @click.stop='changeType("numberSlide")'> 
			{{$t(`elements.outcomeQuestions.numberSlide`)}}
		</div>
		<!--		
			<div class='miniButton' :class='{ primary: type==="textBlock" }' @click='changeType("textBlock")'> 
				{{$t(`elements.outcomeQuestions.textBlock`)}}
			</div>
			<div class='miniButton' :class='{ primary: type==="formula" }' @click='changeType("formula")'> 
				{{$t(`elements.outcomeQuestions.formula`)}}
			</div>		
		-->
	</div>	
</div>
</template>

<script>
export default {
	name: 'ElementTypeInput',
	props: ['type'],
	data() { return { showMenu: false, timer: null }},
	methods: {
		startCloseTimer() {
			document.addEventListener('click', this.close)
			this.timer=setTimeout(()=>this.showMenu=false, 1000)
		},
		stopCloseTimer() {
			clearTimeout(this.timer)
		},
		close() {
			document.removeEventListener('click', this.close)			
			this.stopCloseTimer()
			this.showMenu=false
		},
		changeType(type) {
			this.$emit('changeType', type)
			this.close()
		}
	}
}
</script>

<style lang='scss'>
.elementTypeInput {
	position: relative;

	.label { margin-right: $size-gutter; color: $color-primary-accent; white-space: nowrap; }

	.smallIcon { color: $color-primary-accent; transform: rotate(180deg); }

	.menu {
		position: absolute;
		z-index: 100;
		top: 0;
		left: 0px;
		width: 100%;
		background: $color-neutral-silver;
		border: 1px solid $color-neutral-shadow;
		border-top: none;
		font-weight: normal;
		box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);
		padding: $size-gutter;
		transition: opacity 200ms linear;
		opacity: 0;
		pointer-events: none;
		> div:not(:last-child) { margin-bottom: 1px; }
		> div { border-radius: 2px;  padding: 0 $size-gutter * 2; }

		&.showMenu {
			opacity: 1;
			pointer-events: auto;
			> div { pointer-events: auto; }
		}
	}
}
</style>