<template>
<div class='clientsChooserItem' @click='$emit("click", item)'>
	<div class='nameCol'>
		<span>
			<span>{{item.lastname}}, {{item.firstname}}</span>
			<span class='email'>{{item.email}}</span>		
		</span>
	</div>
	<div class='emailCol'>
		<span>{{item.clinicianName}}</span>		
	</div>
</div>
</template>

<script>
export default {
	name: 'ClientChooserItem',
	components: {},
	props: ['item'],
	computed: {},
	methods: {	
		select() {
			
		}
	}
}
</script>

<style lang='scss'>
.clientsChooserItem {
	background: $color-white;
	display: flex;
	align-items: center;
	position: relative;
	height: $size-control-height;
	margin: 0 $size-gutter * 2;
	cursor: pointer;

	&:hover { background: $color-neutral-silver; }
	&.selected { background: $color-focus !important; }

	> div {
		flex: 1;
		height: $size-control-height;		
		padding: $size-gutter * 2;
		border-left: 1px solid $color-neutral-shadow;
		display: flex;
		align-items: center;
		justify-content: left;
		min-width: 0;
		overflow: hidden;
		font-size: $size-font-standard;
		line-height: $size-lineHeight-small;

		> span > span { display: block; &.email { font-size: $size-font-small; color: $color-primary-accent; }}

		&:last-child {
			border-right: 1px solid $color-neutral-shadow;
		}
	}

}
</style>