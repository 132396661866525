<template>
<div class='manualOutcome'>
	<div class='content'>
		<CloseButton @click='close' />
		<Scroll ref='scroll' v-if='step===1'>		
			<div class='header'>Please complete the following questonnaire...</div>
			<OutcomePreview :def='def' @scrollToTop='scrollToTop' :result='result' @submit='onSubmit' @err='onErr' />
			<CancelButton class='cancelButton' @click='close' />
		</Scroll>
		<div v-else-if='step===2' class='verify'><div>
			<div class='header'>Verify</div>
			<div class='label'>
				<div>
					You are about to manually record the <b>{{def.title}}</b> questionaire for <b>{{client.firstname}} {{client.lastname}}.</b><br /><br />
					<div class='manualDate'>
						<span @click='showBackdate'>Date: <b>{{manualDate.toISOString().split('T')[0]}}</b></span><div class='miniButton neutral' @click='showBackdate'>Change</div>
					</div>
					<span class='manualScore' v-for='(formula, index) in def.sections.map(section=>section.elements.filter(element=>element.type==="formula")).flatten()' :key='index'>
						{{formula.desc}}: <b>{{result[formula.id]}}</b><br />
					</span><br />
					By clicking <b>Record</b> below, you confirm that this is correct and authorized by <b>{{$store.state.profile.user.firstname}} {{$store.state.profile.user.lastname}}.</b>					
				</div>
				<div class='iconTextButton neutral' @click='redo'>
					<svg class='smallIcon'>
						<use xlink:href='#redo' />
					</svg>
					<span class='label'>Redo Questionnaire</span>
				</div>
			</div>	
			<div class='controls'>
				<CancelButton @click='close' />
				<div class='iconTextButton success' @click='onVerify'>
					<svg class='smallIcon'><use xlink:href='#check' /></svg>
					<span class='label'>Record {{def.title}} for {{client.firstname}} {{client.lastname}}</span>
				</div>					
			</div>		
		</div></div>
	</div>
	<!--
	<div class='footer' v-if='showFooter'>
		<b>IMPORTANT:</b> Please verify all answers are correct before clicking <b>SUBMIT.</b><br />
		By clicking <b>SUBMIT,</b> you confirm that this outcome is intended for <b>{{client.firstname}} {{client.lastname}}</b> and is authorized by <b>{{$store.state.profile.firstname}} {{$store.state.profile.lastname}}.</b>
	</div>
-->
</div>
</template>

<script>
//import { fill } from '@/utilities'
//import SmallModal from '@/components/common/modals/SmallModal'
import { isDate } from 'lodash'
import { smallModalMixin } from '@/components/common/mixins/modal'
import Scroll from '@/components/common/Scroll'
import OutcomePreview from '@/components/clinician/outcomes/engine/render'
import CancelButton from '@/components/common/buttons/Cancel'
import CloseButton from '@/components/common/buttons/Close'
import Alert from '@/components/common/modals/Alert'
import Confirm from '@/components/common/modals/Confirm'
import Backdate from './Backdate'

export default {
	name: 'ManualOutcome',
	mixins: [smallModalMixin],
	components: { OutcomePreview, Scroll, CancelButton, CloseButton },
	props: ['def', 'client', 'fn', 'verify', 'disabled'],
	data() { return {
		result: {},
		manualDate: new Date(),
		step: 1
	}},
	computed: {},
	methods: {
		scrollToTop() { this.$refs.scroll.scrollToTop() },
		onSubmit(result) {
			const todayDisabled = () => {
				const today = this.manualDate.toISOString().split('T')[0]
				return this.disabled.some(d=>{
					if (isDate(d)) return d.toISOString().split('T')[0]===today
					else return true
				})
			}
			if (this.verify && todayDisabled()) {
				this.showSmallModal(Backdate, { fn: backdate=> {
					this.manualDate = backdate
					this.step = 2
				}, disabled: this.disabled }, '742px')
			} else if (this.verify) {
				this.step=2				
			} else {
				this.fn(result, this.manualDate)
				this.$emit('close')
			}
		},
		onVerify() {
			this.fn(this.result, this.manualDate)
			this.$emit('close')
		},
		showBackdate() {
			this.showSmallModal(Backdate, { fn: backdate=> {
				this.manualDate = backdate
			}, disabled: this.disabled }, '742px')
		},
		redo() {
			this.result = {}
			this.step = 1
		},
		onErr() {
			this.showSmallModal(Alert, { title: 'You are missing some questions', message: 'Complete the highlighted questions before proceeding.'  })
		},
		close() {
			if (Object.values(this.result).some(v=>v!==undefined)) {
				this.showSmallModal(Confirm, { 
					title: 'Exit?', 
					message: 'You have partially completed this questionnaire.  Your data will be lost.  Are you sure?',
					ok: 'Exit',
					fn: ()=>this.$emit('close')
				})

			} else {
				this.$emit("close")
			}
		}
	}
}
</script>

<style lang='scss'>
.manualOutcome {
	position: absolute;
	display: grid;
	grid-template-rows: 1fr auto;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: $color-neutral-panel;
	overflow: auto;

	.header {
		font-size: $size-font-large;
		color: $color-primary-accent;
		font-weight: bold;
		text-align: center;
		padding: $size-gutter * 4;
		text-align: center;
	}

	> .content {
		position: relative;
		height: 100%;
		.scrollContent { padding: 0 $size-gutter * 4; padding-bottom: $size-gutter * 4; }
	/*	.customOutcomeRender div { font-size: $size-font-large !important; line-height: 150% !important; } */
		.controlBar { padding: $size-gutter * 2; gap: $size-gutter * 2; }
		.nextButton, .backButton { 
			color: $color-primary-accent; font-weight: bold; text-decoration: underline; 
			height: 60px;
		}
		.submitButton {
			height: 60px;
			background: $button-gradient-success;
			color: #fff;
			&:hover { background: $button-gradient-success; color: #fff; }		
		}

		.cancelButton { margin-top: $size-gutter * 2; }

	}

	.verify {
		width: 100%; height: 100%;
		display: flex;
		align-items: flex-start;
		> div {
			margin: 0 $size-gutter * 6;
			padding: $size-gutter * 4;
			padding-top: 0;
			border: 1px solid $color-neutral-shadow;
			background: #fff;
			width: 100%;
			font-size: $size-font-standard;
			line-height: $size-lineHeight-standard;
			margin-top: $size-gutter * 6;
		}
		.label { 
			display: flex;
			> div:first-child { flex: 1; }
			> div:last-child { border: 1px solid $color-neutral-shadow; margin-left: $size-gutter * 2; }
		}
		.manualDate {
			display: flex;
			align-items: center;
			margin-bottom: $size-gutter * 2; 
			.miniButton { margin-left: $size-gutter * 2; border: 1px solid $color-neutral-shadow; }
			b { color: $color-primary-accent; text-decoration: underline; cursor: pointer; }
		}

		.controls { 
			margin-top: $size-gutter * 4; 
			display: flex;
			> div { height: 60px; flex: 1; align-items: center;}
		}
	}

	.closeButton {
		position: absolute;
		top: 0;
		right: 0;
		cursor: pointer;
		z-index: 100;
	}



	.footer {
		padding: $size-gutter * 3;
		background: $color-focus;
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		text-align: center;
		b { color: $color-primary-accent; }
	}
}
</style>