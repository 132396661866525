<template>
<div class='checkbox'>
	<div v-if='index' class='no'><div>{{index}}.</div></div>
	<div class='question' @click='toggle' >
		<CheckButton :checked='selected' />
		<div class='desc' v-html='def.desc' />
	</div>
</div>
</template>

<script>
import CheckButton from '@/components/common/buttons/Check'

export default {
	name: 'Checkbox',
	components: { CheckButton },
	props: ['value','def','index'],
	computed: {
		hasFormulaVals() { 
			return 'valSelected' in this.def 
		},
		selected() {
			return this.hasFormulaVals ? this.value===this.def.valSelected : this.value
		}
	},
	methods: {
		toggle() {
			if (this.selected) this.setUnselected()
			else this.setSelected()
		},
		setSelected() { this.update(this.hasFormulaVals ? this.def.valSelected : true) },
		setUnselected() { this.update(this.hasFormulaVals ? this.def.valUnselected : false) }, 
		update(v) { this.$emit('input', v ) }
	},
	mounted() {
		if (this.value === undefined) this.setUnselected()
	}
}
</script>

<style lang='scss'>
.customOutcomeRender .checkbox {
	.question { 
		display: flex; 
		align-items: center;
		cursor: pointer;
		.desc { margin-left: $size-gutter * 2; flex: 1; margin-bottom: 0;  }
		.checkButton { pointer-events: none; }
	}
}
</style>