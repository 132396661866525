<template>
<div class='clientOutcomeChooser'>
	<Chooser 
		:edit='false' 
		@select='onSelect' 
	/>
	<div class='modalControls'>
		<CancelButton @click='$emit("close")' />		
	</div>
</div>
</template>

<script>
import CancelButton from '@/components/common/buttons/Cancel'	
import Chooser from '@/components/clinician/outcomes/Chooser'

export default {
	name: 'ClientOutcomeChooser',
	components: { Chooser,CancelButton },
	props: ['fn'],
	methods: {
		onSelect(v) { 
			this.fn(v)
			this.$emit('close')
		}
	}
}
</script>

<style lang='scss'>
.clientOutcomeChooser {
	@include fill;
	display: grid;
	grid-template-rows: 1fr auto;
}
</style>
