<template>
<SmallModal class='outcomeExistsModal' @close='$emit("close")' :showCancel='false'>
	<template v-slot:title>{{title}}</template>
	<template v-slot:message>
		<p>{{$t('views.outcomeExists.msg')}}</p>
	</template>
	<template v-slot:buttons>
		<div class='textButton neutral' @click='fnInspectClient(client.id)'>
			<span class='label'>Inspect {{client.firstname}} {{client.lastname}}</span>
		</div>		
		<div class='textButton primary' @click='$emit("close")'>
			<span class='label'>Yes, Continue</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import { fill } from '@/utilities'
import SmallModal from '@/components/common/modals/SmallModal'

export default {
	name: 'OutcomeExists',
	components: {SmallModal},
	props: ['type', 'client', 'fnInspectClient', 'fnCanceled'],
	data: () => ({ canceled: false }),
	computed: {
		title() {
			return fill(this.$t('views.outcomeExists.title'), { outcome: this.type, client: `${this.client.firstname} ${this.client.lastname}` })
		}
	},
	beforeDestroy() {
		if (this.canceled) this.fnCanceled()
	}
}
</script>

<style lang='scss'>
.outcomeExistsModal {

}
</style>