<template>
<div class='longComment'>
	<div v-if='index' class='no'><div>{{index}}.</div></div>
	<div class='question'>
		<div class='desc' v-html='def.desc' />
		<PlainText :value='value' @input='update' placeholder='Type here...' maxlength='500' />
	</div>
</div>
</template>

<script>
import PlainText from '@/components/common/PlainText'	

export default {
	name: 'LongComment',
	components: { PlainText },
	props: ['value','def','index'],
	methods: {
		update(v) { this.$emit('input', v )}
	}
}
</script>

<style lang='scss'>
.customOutcomeRender .longComment {

	.plainText { border: 1px solid $color-neutral-shadow; margin-top: $size-gutter * 2; }
	&.required .plainText { border-color: $color-alert; }

}
</style>