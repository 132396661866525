<template>
<DateInput 
	mode='multiple' 
	:minDate='minDate'
	:maxDate='maxDate'
	:rows='1'
	class='pickReminders'
	v-model='innerValue' 
/>
</template>

<script>
import dayjs from 'dayjs'
import DateInput from '@/components/common/DateInput'

export default {
	name: 'PickEndDate',
	components: { DateInput },
	props: ['expiresOn', 'intervalType', 'value'],
	data() { return {
		minDate: (new Date()).setHours(0,0,0,0),
		innerValue: this.value || []
	}},
	computed: {
/*
		rows() {
			return dayjs(this.maxDate).month() - dayjs(this.minDate).month() >= 2 ? 2 : 1
		},
*/
		maxDate() {
			return new Date(dayjs(this.expiresOn))
		}
	},
	methods: {
		setDays() {
			let start = dayjs(this.minDate)
			const end = dayjs(this.maxDate)
			const diff = end.diff(start, 'day')
			const dates = []
			const fn = cond => {
				for (let i = 0; i <= diff; i++) {
					const d = start.format('dd')
					if (cond(d, i%2)) dates.push(new Date(start.format()))
					start = start.add(1, 'day')
				}				
			}
			switch (this.intervalType) {
				case 'every': 
					fn(() => true)
					break
				case 'alt': 
					fn((d, mod) => !mod)
					break
				case 'MoWeFr': 
					fn(d => ['Mo','We','Fr'].includes(d))
					break
				case 'MoTh': 
					fn(d => ['Mo','Th'].includes(d))
					break
				case 'TuFr':
					fn(d => ['Tu','Fr'].includes(d))
					break
				case 'MoFr': 
					fn(d => ['Mo','Fr'].includes(d))
					break
				case 'Su': 
					fn(d => ['Su'].includes(d))
					break
				case 'Mo':
					fn(d => ['Mo'].includes(d))
					break
				case 'Tu': 
					fn(d => ['Tu'].includes(d))
					break
				case 'We': 
					fn(d => ['We'].includes(d))
					break
				case 'Th': 
					fn(d => ['Th'].includes(d))
					break
				case 'Fr':
					fn(d => ['Fr'].includes(d))
					break
				case 'Sa': 
					fn(d => ['Sa'].includes(d))
					break
				case 'altSu': 
					fn((d, mod) => !mod && ['Su'].includes(d))
					break
				case 'altMo': 
					fn((d, mod) => !mod && ['Mo'].includes(d))
					break
				case 'altTu': 
					fn((d, mod) => !mod && ['Tu'].includes(d))
					break
				case 'altWe': 
					fn((d, mod) => !mod && ['We'].includes(d))
					break
				case 'altTh': 
					fn((d, mod) => !mod && ['Th'].includes(d))
					break
				case 'altFr': 
					fn((d, mod) => !mod && ['Fr'].includes(d))
					break
				case 'altSa': 
					fn((d, mod) => !mod && ['Sa'].includes(d))
					break									
			}	
			this.innerValue = dates	
		}
	},
	watch: {
		intervalType(a, b) {
			if (a !== b) this.setDays()
		},
		innerValue(a, b) {
			if (a !== b) this.$emit('input', a)
		},
		expiresOn(a, b) {
			if (a !== b) {
				if (this.intervalType) {
					this.setDays()
				} else {
					const v = []
					this.innerValue.forEach(d => {
						if (dayjs(d).isBefore(dayjs(this.expiresOn))) v.push(d)
					})
					this.innerValue = v
				}
			}
		}
	},
	created() {
		if (this.intervalType && (!this.value || !this.value.length)) this.setDays()
	}
}
</script>

<style lang='scss'>
</style>

