<template>
<div class='multipleChoice'>
	<div v-if='index' class='no'><div>{{index}}.</div></div>
	<div class='question'>
		<div class='desc' v-html='def.desc' />
		<div class='choices'>
			<div v-for='(choice, i) in def.choices' :key='i' @click='select(i)'>
				<CheckButton :checked='isSelected(i)' />
				<div>{{choice.desc}}</div>
			</div>
		</div>
	</div>
</div>
</template>

<script>
import CheckButton from '@/components/common/buttons/Check'

export default {
	name: 'MultipleChoice',
	components: { CheckButton },
	props: ['value','def','index'],
	computed: {
		hasFormulaVals() {
			return !!this.def.choices.find(c=>'val' in c)
		}
	},
	methods: {
		isSelected(i) {
			if (this.hasFormulaVals) {
				return this.def.choices[i].val === this.value
			} else {
				return i === this.value
			}
		},
		select(i) {
			if (this.isSelected(i)) {
				this.update(undefined)
			}
			else if (this.hasFormulaVals) {
				this.update(this.def.choices[i].val)
			} else {
				this.update(i)
			}
		},
		update(v) { this.$emit('input', v ) }
	}
}
</script>

<style lang='scss'>
.customOutcomeRender .multipleChoice {

	.choices {
		margin-top: $size-gutter * 2;
		> div {
			display: flex;
			align-items: center;
			margin-top: $size-gutter;
			margin-left: $size-gutter * 2;
			cursor: pointer;
			> div:last-child { flex: 1; margin-left: $size-gutter * 2; font-size: $size-font-standard; line-height: $size-lineHeight-standard; }
		}
	}

	.checkButton { pointer-events: none;  }

	&.required .checkButton { background: #fff; border-radius: 50%; color: $color-alert; }

}
</style>