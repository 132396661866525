<template>
<div class='outcomeManager'>
	<Chooser 
		ref='chooser'
		:edit='true' 
		:folders='folders' 
		@folderUpdate='onFolderUpdate' 
		@moved='onMoved' 
		@rename='onRename' 
		@select='onSelect' 
	/>
	<Item 
		ref='item' 
		:outcomeId='outcomeId' 
		:folders='folders' 
		@rename='onRename' 		
		@saved='$refs.chooser.fetchTop()' 
	/>
</div>
</template>

<script>
	import { outcomesApi } from '@/services/api/modules/clinician/outcomes'

import { smallModalMixin } from '@/components/common/mixins/modal'	
import ConfirmModal from '@/components/common/modals/Confirm'	
import TextInputModal from '@/components/common/modals/TextInput'
import Chooser from './Chooser'
import Item from './Item'

export default {
	name: 'OutcomesManager',
	components: { Chooser, Item },
	mixins: [smallModalMixin],
	data() { return {
		folders: {},
	}},
	computed: {
		outcomeId() { return this.$route.params.outcomeId }
	},
	methods: {
		onSelect(outcomeId) {
			if (this.$store.state.bus.bussing) return false
			this.$router.push({ params: { outcomeId }}).catch(()=>{})
		},
		onMoved({ outcomeId, folderId }) {
			if (this.$route.params.outcomeId==outcomeId) this.$refs.item.attrs.folderId = folderId
		},
		onRename(item) {
			const fn = (savename) => {
				this.$refs.chooser.$refs.list.working = true 
				const payload = { id: item.id, savename }
				outcomesApi.touch('update', payload)
					.then(([err]) => {
						if (!err) {
							this.$refs.chooser.$refs.list.fetchTop()
							if (this.$route.params.outcomeId==item.id) {
								this.$refs.item.attrs.savename = savename  
							}
						}
					})
					.finally(() => this.working = false)			
			}

			this.showSmallModal(TextInputModal, {
				title: 'Rename Outcome...',
				placeholder: 'Outcome...',
				value: item.savename,
				button: this.$t('elements.buttons.saveAs'),
				maxlength: 100,
				fn
			})			


			
		},
		onFolderUpdate(val) {
			this.folders = val
		},	
		checkSaved(next) {
			if (this.$refs.item && this.$refs.item.unsaved) {
				this.showSmallModal(ConfirmModal, {
					title: this.$t('views.confirmExitOutcome.title'),
					message: this.$t('views.confirmExitOutcome.p1'),
					ok: this.$t('elements.buttons.continue'),
					fn: () => next()
				})
			} else {
				next()
			}
		}
	},
	watch: {
		outcomeId(v) {
			this.$store.dispatch('manageOutcomes/setSelectedId', v)
		}
	},
	created() {
		if (!this.$route.params.outcomeId && this.$store.state.manageOutcomes.selectedId)
			this.$router.push({ params: { outcomeId: this.$store.state.manageOutcomes.selectedId }}).catch(()=>{})
	},
	beforeRouteUpdate(to, from, next) {
		this.checkSaved(next)
	}
}
</script>

<style lang='scss'>
.outcomeManager {
	min-width: 1020px;
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: 1fr 1fr;
		background: repeating-linear-gradient(
			180deg,
			darken($color-neutral, 5%),
			darken($color-neutral, 5%) 14px,
			$color-neutral-panel 14px,
			$color-neutral-panel 28px
		);
		background-position: 0 -14px;
		> div { background: $color-neutral-panel; &:last-child { margin-left: 1px; }}		
}

/*
@media screen and (max-width: 1200px) {

	.outcomeManager {

		grid-template-columns: 3fr 4fr;
		.outcomeChooser { grid-template-columns: 1fr; }
		.outcomesList { padding-left: $size-gutter * 2; }
		.outcomeFolders { display: none; }
		.subfolders { display: grid; }

	}

}
*/
</style>