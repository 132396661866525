<template>
<div class='selectInput'>
	<select
		@input='onInput'
		:value='value'
	>
		<slot />
	</select>
	<div class='caret'><svg><use xlink:href='#caret' /></svg></div>
</div>
</template>

<script>
export default {
	name: 'SelectInput',
	props: ['value'],
	methods: {
		onInput(e) {
			this.$emit('input', e.target.value)
		}
	}
}
</script>

<style lang='scss'>
.selectInput {
	position: relative;

	select {
		appearance:none;
		outline: none;
		font-family: 'Cabin', sans-serif;
		font-size: $size-font-standard;
		height: $size-control-height + 2;
		padding: 0 $size-gutter * 2;
		border: 1px solid $color-neutral-shadow;
		-moz-appearance: none;
		-webkit-appearance: none;
		width: 100%;
	}

	.caret {
		position: absolute;
		z-index: 1;
		top: 0;
		right: 0;
		height: 100%;
		padding: 0 $size-gutter * 2;
		background: $button-gradient-primary;
		content: '';
		pointer-events: none;
		display: flex;
		align-items: center;
		justify-content: center;

		svg { 
			width: 12px;
			transform: rotate(180deg);
			color: $color-white;
		}
	}
}
</style>

