<template>
<div class='outcomeChooser'>
	<Folders 
		@update='onFolderUpdate' 
		@drop='onFolderDrop' 
		:edit='edit'
	/>
	<List 
		ref='list' 
		:folders='folders || innerFolders' 
		:selected='$route.params.outcomeId'
		:edit='edit'		
		@moved='v=>$emit("moved", v)' 
		@rename='v=>$emit("rename", v)' 
		@select='v=>$emit("select", v)' 
	/>
</div>
</template>

<script>
/* 
This is pretty good, but we still have the manageOutcomes store dependency in here
Eventually when rewriting this stuff, the pinia store should be passed in as a prop as well
*/

import Folders from './Folders'
import List from './List'

export default {
	name: 'OutcomeChooser',
	components: { Folders, List },
	props: ['edit','folders'],
	data() { return { innerFolders: {} }},
	methods: {
		fetchTop() { this.$refs.list.fetchTop() },
		onFolderUpdate(v) {
			if (this.folders) {
				this.$emit("folderUpdate", v)
			} else {
				this.innerFolders = v
			}
		},
		onFolderDrop(val) {
			if (val.folderId !== val.data[0].folderId)
				this.$refs.list.moveOutcome(val.data[0].id, val.folderId)
		}
	}
}
</script>

<style lang='scss'>
.outcomeChooser {
	@include fill;
	display: grid;
	grid-template-columns: minmax(220px, 1fr) 2fr;
	background: $color-neutral-panel;
	.subfolders { display: none; }
	/* .readOnly { display: none; } */
}

</style>
