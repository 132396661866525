<template>
<ElementHOC 
	:def='def' 
	:pos='pos' 
	:autoNumber='autoNumber' 
	:showId='true'
	:canToggleRequired='true'
	:active='active'
	:summable='summable'
	:index='index'
	:errs='errs'		
	ref='hoc'		
	placeholder='Question...' 
	class='numberSlide'
	v-on='$listeners'
>
	<div class='values'>
		<div>
			<TextInput 
				v-model='def.labelMin' 
				placeholder='Min Label...' 
				maxlength='30' 
				class='label'
			/>	
			<span class='equals'>＝</span>
			<TextInput 
				v-model='def.min' 
				placeholder='Value...' 
				maxlength='4' 
				:numeric='true'
				class='value'
			/>		
		</div>			
			<div>
			<TextInput 
				v-model='def.labelMax' 
				placeholder='Max Label...' 
				maxlength='30' 
				class='label'
			/>	
			<span class='equals'>＝</span>
			<TextInput 
				v-model='def.max' 
				placeholder='Value...' 
				maxlength='4' 
				:numeric='true'
				class='value'				
			/>		
		</div>			
	</div>
</ElementHOC>
</template>

<script>
import TextInput from '@/components/common/TextInput'	
import ElementHOC from './HOC'

export default {
	name: 'NumberSlide',
	components: { ElementHOC, TextInput },
	props: ['def','pos','autoNumber','active','summable','index'],
	data() { return {
		errs: []
	}},	
	methods: {
		validate() {
			this.errs = []
			if (!Number.isInteger(this.def.min)) this.errs.push('Minimum value required')
			if (!Number.isInteger(this.def.max)) this.errs.push('Maximum value required')
			if (this.def.min>=this.def.max) this.errs.push('Minimum must be less than maximum')
			const success = this.$refs.hoc.validate()
			return success && !this.errs.length
		}
	}
}
</script>

<style lang='scss'>
.customOutcomeEditor .numberSlide {

	.values {

		display: flex;
		align-items: center;

		> div {
			display: flex;
			align-items: center;			
		}

		.label { flex: 3; }
		.equals { 
			font-size: $size-font-large;
			color: $color-primary-accent;
		}
		.value { flex: 1; }
	}

	.labels {
		display: flex;
		align-items: center;		
	}

}
</style>