<template>
<ElementHOC 
	:def='def' 
	:pos='pos' 
	:autoNumber='autoNumber'
	:canAddFormulaVals='true'
	:hasFormulaVals='hasFormulaVals'
	:active='active'
	:summable='summable'	
	:showId='hasFormulaVals'
	:index='index'	
	:errs='errs'
	@addFormulaVals='addFormulaVals'
	@removeFormulaVals='removeFormulaVals'
	ref='hoc'
	placeholder='Question...' 
	class='checkbox'
	v-on='$listeners'
>
	<div v-if='hasFormulaVals' class='values'>		
		<div>
			<span class='label'>Checked</span>
			<span class='equals'>＝</span>
			<TextInput 
				v-model='def.valSelected' 
				placeholder='Value...' 
				maxlength='4' 
				class='value'
				:numeric='true'
			/>		
		</div>
		<div>
			<span class='label'>Unchecked</span>
			<span class='equals'>＝</span>
			<TextInput 
				v-model='def.valUnselected' 
				placeholder='Value...' 
				maxlength='4' 
				class='value'
				:numeric='true'
			/>		
		</div>	
	</div>
</ElementHOC>
</template>

<script>
import TextInput from '@/components/common/TextInput'
import ElementHOC from './HOC'

export default {
	name: 'Checkbox',
	components: { ElementHOC, TextInput },
	props: ['def','pos','autoNumber','active','summable','index'],
	data() { return {
		errs: []
	}},
	computed: {
		hasFormulaVals() {
			return 'valUnselected' in this.def || 'valSelected' in this.def
		}
	},
	methods: {
		addFormulaVals() {
			this.$set(this.def, 'valUnselected', 0)
			this.$set(this.def, 'valSelected', 1)
		},
		removeFormulaVals() {
			this.$delete(this.def, 'valUnselected')
			this.$delete(this.def, 'valSelected')
		},
		validate() {
			this.errs = []
			if (this.hasFormulaVals) {
				if (!Number.isInteger(this.def.valUnselected) || !Number.isInteger(this.def.valSelected)) this.errs.push('Formula values required')
				else if (this.def.valUnselected===this.def.valSelected) this.errs.push('Formula values must be unique')
			}
			const success = this.$refs.hoc.validate()
			return success && !this.errs.length
		}
	}
}
</script>

<style lang='scss'>
.customOutcomeEditor .checkbox {

	.values {

		display: flex;
		align-items: center;

		> div {

			display: flex;
			align-items: center;

			.label { font-size: $size-font-standard; white-space: nowrap; flex: 1; padding-left: $size-gutter * 2; }
			.equals { 
				font-size: $size-font-large;
				color: $color-primary-accent;
			}
			.textInput { flex: 1; }

		}

	}

}
</style>