<template>
<div 
	class='outcomeDefListItem' 
	:class='{ selected: selected == item.id }'
	@click='onClick'
	@mousedown='onMouseDown'
>
	<div class='title'>
		<span class='overflow savename'>{{item.savename}}</span>
		<!--
		<div 
			v-if='canEdit'
			class='renameButton miniButton neutral' 
			@click.stop='rename'
		>{{ $t('elements.buttons.rename') }}</div>		
		-->
		<svg v-if='!canEdit' class='readOnly miniIcon typeIcon'>
			<use xlink:href='#lock' />
		</svg>			
		<span class='overflow path' v-html='path' />			
	</div>
</div>
</template>

<script>
import { Distance } from '@/utilities'

export default {
	name: 'OutcomeDefListItem',
	components: { },
	props: ['item','path','canEdit','selected'],
	methods: {
		onMouseDown(e) {
			if (e.which && e.which === 3) {
				//e.stopImmediatePropagation()
				this.$emit('showContextMenu', e, this.item)				
			} else if (this.canEdit && !this.$store.state.main.mobile) new Distance({
				onPull: () => {
					this.$store.dispatch('bus/loadBus', {
						data: [this.item],
						contentType: 'list'
					})
				},
				e
			})
		},		
		onClick(e) {
			this.$emit("select", this.item.id)
		}
	}
}
</script>

<style lang='scss'>
.outcomeDefListItem {
	background: $color-white;
	display: flex;
	align-items: center;
	position: relative;
	height: $size-control-height;
	margin: 0 $size-gutter * 2;
	margin-left: 0;

	&:hover { background: $color-neutral-silver; }
	&.selected { background: $color-focus !important; }

	> div {
		flex: 1;
		padding: $size-gutter * 2;
		border-left: 1px solid $color-neutral-shadow;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: left;
		min-width: 0;
		overflow: hidden;
		font-size: $size-font-standard;

		&:last-child {
			border-right: 1px solid $color-neutral-shadow;
		}

		&:not(.delete) {
			cursor: pointer;
		}

		> div > div { font-size: $size-font-small; color: $color-primary-accent; }
	}

	.title {
		flex: 1;
		.savename { margin-right: $size-gutter * 2; }
		.path { color: $color-neutral-dark; }
		.miniButton { margin-left: $size-gutter * 2; }
		.typeIcon { color: $color-neutral-dark; margin-right: $size-gutter; }
		> span { white-space: nowrap;}
		> span.path { 
			font-size: $size-font-small; 
		}
	}

	.renameButton { border: 1px solid $color-neutral-shadow; }

	.overflow { overflow: hidden; min-width: 0; text-overflow: ellipsis; }


}
/*
@media screen and (max-width: 1280px) {

	.outcomeDefListItem .title {
		.path { display: none !important; }
	}

}
*/
</style>